import { UPDATE_LIVE_TAILING, UPDATE_LOCATION_ARROW, FETCH_ROUTE_HISTORY, FETCH_LENGTH } from "./types"
import { store } from '../index';
import length from '@turf/length';

export const fetchLiveLocationHistory: any = (coordinates: any) => {
    return (dispatch: any) => {
        dispatch({ type: FETCH_ROUTE_HISTORY, payload: { coordinates: coordinates }});

        if (store.getState().race.racestate.liveEnded) {
            const location = store.getState().liveLocation.liveTail;
            const units = store.getState().race.units;
            const distance = length(location, { units: units }).toFixed(2);
            dispatch({ type: FETCH_LENGTH, payload: distance })
        }
    };
};

export const updateLiveLocation: any = (coordinates: number[], bearing: number) => {
    return (dispatch: any) => {
        dispatch({ type: UPDATE_LOCATION_ARROW, payload: { coordinates: coordinates, bearing: bearing }});
        dispatch({ type: UPDATE_LIVE_TAILING, payload: { coordinates: coordinates }});

        const location = store.getState().liveLocation.liveTail;
        const units = store.getState().race.units;
        const distance = length(location, { units: units }).toFixed(2);
        dispatch({ type: FETCH_LENGTH, payload: distance })
    };
};
