import { useState } from 'react';
import { audioRecorderHelper } from '../helpers/audioRecorderHelper';
import { useDispatch } from 'react-redux';
import { REQUEST_ERROR, RESET_SENDER } from '../store/messages/types';

interface VoiceMessage {
    recording: boolean,
    time: number,
    timeFormatted: string,
    recordingStopped: boolean,
    audioMessageTooShort:boolean
};

interface MediaRecorder {
    mediaRecorder: any | null,
    voiceMessage: any | null,
    voiceMessageAudio: any | null,
    listening: any | null,
    timer: any | null
};

const initialMediaRecordObject = { mediaRecorder: null, voiceMessage: null, voiceMessageAudio: null, listening: null, timer: null };
const initialVoiceMessageState: VoiceMessage = { recording: false, time: 0, timeFormatted: '', recordingStopped: false, audioMessageTooShort: false }

let mediaRecord: MediaRecorder = { ...initialMediaRecordObject };

const useAudioRecorder = () => {
    const [voiceMessage, setVoiceMessage] = useState({ ...initialVoiceMessageState } as VoiceMessage);
    const { recording, timeFormatted, time, recordingStopped, audioMessageTooShort } = voiceMessage;
    const dispatch = useDispatch();

    const recordButton = () => {
        console.log(time)
        if (recording && time >= 2000) {
            stopRecord();
        } else if (recording && time < 2000) {
            stopRecord();
            clearRecord();
            setAudioTooShortWarning();
        } else {
            record();
        }
    };

    const resetTooShortWarning = () => {
        setVoiceMessage({ ...initialVoiceMessageState, audioMessageTooShort: false})
    }

    const setAudioTooShortWarning = () => {
        setVoiceMessage({ ...initialVoiceMessageState, audioMessageTooShort: true })
    }

    const setStart = () => {
        setVoiceMessage({ time: 0, timeFormatted: "00:00", recording: true, recordingStopped: false, audioMessageTooShort: false })
        setTimer(Date.now())
    };

    const setTimer = (startTime:any) => {
        mediaRecord.timer = setInterval(() => {
            const now = Date.now()
            const diff = (now - startTime)
            let seconds = (diff / 1000).toFixed(0)
            if (Number(seconds) < 10) {
                seconds = '0' + seconds
            }
            if (Number(seconds) >= 30) {
                //autostop
                stopRecord(diff, `00:${seconds}`)
            } else {
                setVoiceMessage({ recording: true, time: diff, timeFormatted: `00:${seconds}`, recordingStopped: false, audioMessageTooShort: false })
            }


        }, 1000);
    };

    const record = async () => {
        let stream = null;
        if (navigator.mediaDevices) {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                mediaRecord.mediaRecorder = new MediaRecorder(stream) || new audioRecorderHelper(stream);
                mediaRecord.mediaRecorder.addEventListener('dataavailable', (e: any) => {

                    let data = e.data

                    mediaRecord.voiceMessage = data
                    var audioURL = URL.createObjectURL(data);
                    mediaRecord.voiceMessageAudio = new Audio(audioURL)

                    mediaRecord.voiceMessageAudio.addEventListener("ended", () => {
                        console.log("playing ended");
                        mediaRecord.listening = false
                    });
                });

                mediaRecord.mediaRecorder.start();
                setStart();
            } catch (err) {
                console.log('Record error: ', err);
                //If the user doesnt give permission to use the microphone
                if (err.name === 'NotAllowedError') {
                    dispatch({ type: REQUEST_ERROR, payload: 'Please allow Peps Friends to use your microphone to record audio.'});
                    setTimeout(() => {
                        dispatch({ type: RESET_SENDER });
                    }, 4000);
                }
            }
        } else {
            console.log('navigator.mediaDevices is undefined! Not secure connection..?');
            dispatch({ type: REQUEST_ERROR, payload: 'Media devices not found'});
            setTimeout(() => {
                dispatch({ type: RESET_SENDER });
            }, 5000);
        }
    };

    const stopRecord = (time?: number, timeFormatted?: string) => {
        if (time !== undefined && timeFormatted !== undefined) {
            setVoiceMessage({ ...voiceMessage, time, timeFormatted, recording: false, recordingStopped: true })
        } else {
            setVoiceMessage({ ...voiceMessage, recording: false, recordingStopped: true })
        }
        mediaRecord.mediaRecorder.stop()
        clearInterval(mediaRecord.timer)
        if (mediaRecord.mediaRecorder.stream) {
            mediaRecord.mediaRecorder.stream.getTracks().forEach((track:any) => {
                track.stop()
            });
        }
        mediaRecord.mediaRecorder = null
    };

    const playAudio = () => {
        if (mediaRecord.voiceMessageAudio != null && mediaRecord.listening !== true) {
            mediaRecord.listening = true
            mediaRecord.voiceMessageAudio.play()
        }

    }
    const clearRecord = () => {
        setVoiceMessage({ ...initialVoiceMessageState} )
        mediaRecord = { ...initialMediaRecordObject }
    };

    return {
        recordButton,
        playAudio,
        clearRecord,
        resetTooShortWarning,
        recording,
        timeFormatted,
        recordingStopped,
        mediaRecord,
        audioMessageTooShort
    };
};

export default useAudioRecorder;
