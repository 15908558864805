import { FETCH_RESPONSES, FAILED_FETCH_RESPONSES } from './types';
import { FirebaseRefs } from '../../enums/firebaseRefs';
import { getFirebaseRef } from '../../firebase';
import { store } from '../index';
import * as turf from '@turf/helpers';

let listener: any = null;

export const addResponseListener: any = (publicId: string) => {
    return (dispatch: any) => {
        try {
            listener = getFirebaseRef(FirebaseRefs.Responses, publicId).orderByChild('timestamp');
            return listener.on('child_added', (snapshot: any) => {
                dispatch({ type: FETCH_RESPONSES, payload: snapshot.val() });
            });
        } catch (error) {
            dispatch({ type: FAILED_FETCH_RESPONSES, payload: error });
        }
    };
};

export const removeResponseListener: any = () => {
    listener.off('child_added');
};
