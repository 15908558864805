import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import { updateLocationMeters, updateLocationMetersRand, sendMessage, sendVoiceMessage, resetForm } from '../src/store/form/actions'
import { resetSender } from '../src/store/messages/actions'
import send from '../src/svg/send.svg'
import rec from '../src/svg/rec.svg'
import stop from '../src/svg/stop.svg'
import trash from '../src/svg/ic_trash.svg'
import loader from '../src/svg/loader.svg'
import { motion } from "framer-motion"
import { RootState } from "../src/store/index"
import useAudioRecorder from './hooks/AudioRecorderHooks';
import { inputSelectedForTheFirstTime, toggleLocationSliderChanged, firstTextMessageSent } from './store/app/actions';
import useWindowDimensions from './helpers/useWindowDimensions';
import ErrorContainer from './ErrorContainer';
import { useTranslation } from 'react-i18next'
import { enqueueUri } from './store/spotify/actions';
import { ADD_NEW_MESSAGE } from './store/messages/types'

const validate = (values: MessageFormValues) => {
    const errors:any = {};
    if (!values.message) {
        errors.message = 'Required';
    }
    console.log(errors)
    return errors;
}

interface MessageFormValues {
    message: string,
}

const BottomBar = (props: any) => {

    const { t, i18n } = useTranslation()
    const [distanceSlider, setDistanceSlider] = useState(false)
    const selectState = (state: RootState) => state
    const { race, form, message, spotify, appState } = useSelector(selectState)
    const dispatch = useDispatch()
    const { recordButton, playAudio, clearRecord, resetTooShortWarning, recordingStopped, timeFormatted, recording, mediaRecord, audioMessageTooShort } = useAudioRecorder();
    const { width } = useWindowDimensions();
    const desktop = (width > 1200);
    const [audioSupported, setAudioSupported] = useState(false);
    const [token, setToken] = useState('');

    const [inputValue, setInputValue] = useState('');
    const [urlAttempt, setUrlAttempt] = useState(false);
    const [spotUri, setSpotUri] = useState('');

    //Render the recaptcha function
    const renderRecaptcha = () => {
        if (!race.racestate.liveEnded) {
            window.grecaptcha.render('recaptcha', {
                'sitekey': '6LdUSLcZAAAAAP9Eb5Bc7smuFnlUe9WpdDuMcAOV',
                'callback': (token: string) => setToken(token),
                'size': 'invisible',
                'expired-callback': () => window.grecaptcha.reset(),
                'error-callback': () => window.grecaptcha.reset(),
            });

            const badgeArea = document.getElementById('recaptcha');
            const label = document.createElement('label');

            label.innerHTML = `This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                <a href="https://policies.google.com/terms">Terms of Service</a> apply.`
            badgeArea?.appendChild(label);
        }
    };

    const authAndValidate = async () => {
        let authResponse: any = {
            access_token: null
        };
        if ((props.accessToken.expires_in && (new Date().getTime() / 1000) > props.accessToken.expires_in) || !props.accessToken.expires_in){
            authResponse = await fetch(`https://us-central1-racepeps.cloudfunctions.net/gettoken`)
            authResponse = await authResponse.json();
            if (!authResponse.access_token){
                setUrlAttempt(true)
            }
            props.setAccessToken({
                access_token: authResponse.access_token,
                expires_in: (new Date().getTime() / 1000) + authResponse.expires_in - 30,
            })
        } else {
            authResponse.access_token = props.accessToken.access_token;
        }

        let trackResponse: any = await fetch(`https://us-central1-racepeps.cloudfunctions.net/validatetrack`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `token=${authResponse.access_token}&track=${inputValue}`,
        })

        const track = await trackResponse.json();

        if (track.error) {
            setUrlAttempt(true)
        } else {
            setUrlAttempt(false)
        }

    }

    //When the component mounts, render the recaptcha first time
    useEffect(() => {
        renderRecaptcha();
    }, []);

    //After the recaptcha challenge is executed, tokens state is changed and this will be called
    //If we put a function to recaptcha callback it will reset recordingStopped state and the audiomessages will not be sent
    useEffect(() => {
        if (token && recordingStopped) {
            handleAudioMessage();
        } else if (token) {
            formik.handleSubmit();
        }
    }, [token]);

    //When message is sent (message.sender.sent === true), render the recaptcha component again
    useEffect(() => {
        if (message.sender.sent) {
            renderRecaptcha();
            dispatch(firstTextMessageSent());
        }
    }, [message.sender.sent]);

    const resetPostForm = (isAudio: boolean) => {
        if (isAudio) {}
        dispatch(resetForm()) //Reset the redux which holds position
        setDistanceSlider(false) //Reset the var which shows slider
        formik.resetForm()
    }

    useEffect(() => {
        if (message.sender.sent) {
            setTimeout(() => {
                dispatch(resetSender())
            }, 1500);
        }
    });

    //Check if the user has a microphone in use. If the user has, show the audio recorder button.
    useEffect(() => {
        const getAudioDevices = async () => {
            if (navigator.mediaDevices) {
                const devices = await navigator.mediaDevices.enumerateDevices();
                devices.forEach(device => {
                    if (device.kind === 'audioinput') {
                        setAudioSupported(true);
                    }
                });
            }
        };

        getAudioDevices();
    }, []);

    useEffect(() => {
        setInputValue('')
    }, [spotify.enqueueTrack.state.connected,spotify.enqueueTrack.state.enqueueTrack]);

    useEffect(() => {
        if (inputValue.length > 0){
            authAndValidate();
        }
    },[spotUri]);

    let dmClass = distanceSlider ? 'dm active':'dm'
    if (form.userSelectedLocation && dmClass === 'dm') {
        dmClass = 'dm set'
    }

    const locationLength = (decimal: number) => {
        if (race.units === 'kilometers') {
            return (Number(form.locationMeters) / 1000).toFixed(decimal);
        } else {
            return ((Number(form.locationMeters) / 1000) * 0.62).toFixed(decimal);
        }
    };

    const inputFocused = () => {
        setDistanceSlider(false);
        dispatch(inputSelectedForTheFirstTime());
    };

    const dmIconClicked = () => {
        dispatch(toggleLocationSliderChanged());
        setDistanceSlider(!distanceSlider);
    };

    const handleMobileOKClick = () => {
        setDistanceSlider(false);
    };

    const initialValues: MessageFormValues = {
            message: '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validate,
        onSubmit: values => {
            let lat = form.position?.[1] ?? 60.1234
            let lng = form.position?.[0] ?? 24.1234
            const live = race.racestate.liveStarted !== 0 && race.racestate.liveStarted !== undefined ? true : false;
            if (form.locationMeters !== '0') {
                dispatch(sendMessage(values.message, [lat, lng], token, live));
                resetPostForm(false);
            } else {
                dispatch(updateLocationMetersRand(Number(race.path.length)));
                dispatch(sendMessage(values.message, [lat, lng], token, live));
                resetPostForm(false);
            }
        },
    });

    const handleAudioMessage = () => {
        let lat = form.position?.[1] ?? 60.1234
        let lng = form.position?.[0] ?? 24.1234
        const live = race.racestate.liveStarted !== 0 && race.racestate.liveStarted !== undefined ? true : false;
        if (form.locationMeters !== '0') {
            dispatch(sendVoiceMessage(mediaRecord.voiceMessage, [lng, lat], timeFormatted, token, live))
            clearRecord()
            resetPostForm(true)
        } else {
            dispatch(updateLocationMetersRand(Number(race.path.length)));
            dispatch(sendVoiceMessage(mediaRecord.voiceMessage, [lng, lat], timeFormatted, token, live))
            clearRecord()
            resetPostForm(true)
        }
    };

    const validateUri = (value: any) => {
        if (!value.includes('https://open.spotify.com/track/') || value.split('https://open.spotify.com/track/')[1].length === 0) {
            return false;
        }
        if (!spotify.enqueueTrack.state.connected || !spotify.enqueueTrack.state.enqueueTrack){
            return false
        }
        let uri = value.split('https://open.spotify.com/track/')[1];
        uri = uri.split('?')[0];
        const pattern = /^[a-zA-Z0-9\s]*$/;
        if (pattern.exec(uri)) {
            setInputValue(uri);
            setSpotUri(uri);
            return true;

        }

        return false;
    }

    return (
        <>
        {message.sender.error ?
        <ErrorContainer error={ message.sender.errorText } />
        :
        null}
        {message.sender.sending ? (
            <div className="BottomBar loading">
                <div className="row center-xy">
                    <motion.div
                        animate={{ rotate: 360 }}
                        transition={{
                            loop: Infinity,
                            ease: "linear",
                            duration: 2
                        }}
                        >
                        <img src={loader}></img>
                    </motion.div>
                    <label className="cyan-bold-18 pad-left-7">{t('BTMBARSending')}</label>
                </div>
            </div>
        ):
        <div>
            {race.racestate.liveEnded ?
            <div className="BottomBar event-over-container">
                <label>{t('BTMBAREventOver')}</label>
            </div>
            :
            <>
            {
                inputValue.length > 0 && !urlAttempt
                ?
                <iframe src={`https://open.spotify.com/embed/track/${inputValue}`} width="100%" height={desktop ? "80" : "90"} allow="encrypted-media" />
                :
                null
            }
            {
                urlAttempt ?
                <div className="urlAttempt">
                    <div className="dm-spotify-history warning">
                        <label>!</label>
                    </div>
                    {
                        spotify.enqueueTrack.state.connected && spotify.enqueueTrack.state.enqueueTrack  ?
                        <label>This doesn't look like a valid spotify link</label>
                        :
                        <label>Links will not be sent</label>
                    }
                </div>
                :
                null
            }
            <motion.div layout={true} transition={{
                type: "spring",
                stiffness: 100,
                duration:0.2
            }}
            className="BottomBar">
            {desktop ?
                distanceSlider && (
                    <div className="Message-Location-Container">
                        <label className="Message-Location">{t('BTMBARSpeakAfter')}</label>
                        <div className="Slider-Container">
                            <input type="range" min='100' max={(race.path.length - 100)} value={form.locationMeters} onChange={(event) => dispatch(updateLocationMeters(event.target.value, race.path.measurePoints)) } className="slider"></input>
                        </div>
                        <label className="Distance-Label">{ locationLength(1) } { race.units === 'kilometers' ? 'km' : 'mi' }</label>
                    </div>)
                :
                null}
            <div className="Message-Row">
                    <div className={(formik.errors.message && inputValue.length === 0) || urlAttempt ? 'Message-Input-Container error' : 'Message-Input-Container'}>
                        {recording ? (
                            <div className="voicemessage-row">
                                <motion.div className="rec-circle"
                                    animate={{
                                        opacity: [1, 0, 1, 0, 1]
                                    }}
                                    exit={{ opacity: 0 }}
                                    transition={{
                                        duration: 2,
                                        ease: "easeInOut",
                                        times: [0, 0.2, 0.5, 0.8, 1],
                                        loop: Infinity,
                                        repeatDelay: 1
                                    }}
                                />
                                <label className="audiomessage-label">{timeFormatted}</label>
                                </div>
                        ):(
                            <Fragment>

                            {race.racestate.liveStarted !== 0 && race.racestate.liveStarted !== undefined ?
                                <div className="dm-live" />
                                :
                                <div className={dmClass} onClick={() => dmIconClicked()}>
                                    <>
                                    {(!distanceSlider && !form.userSelectedLocation) ? (
                                        <label> { race.units === 'kilometers' ? 'km' : 'mi' } </label>
                                    ): (
                                        <label>{ locationLength(0) }</label>
                                    )}
                                    </>
                                </div>
                                }
                                {recordingStopped ? (
                                    <div className="voicemessage-row space-between">
                                                            <label className="audiomessage-label cyan" onClick={() => playAudio()}>{`${t('BTMBarAudio')} (${timeFormatted})`}</label>
                                        <img src={trash} onClick={() => clearRecord()}></img>
                                    </div>
                                ) : (
                                <input
                                    id="message"
                                    name="message"
                                    type="text"
                                    className={audioMessageTooShort ? "Message-Input red-placeholder" : "Message-Input"}
                                    placeholder={audioMessageTooShort ? t('BTMBARInputPlaceholderAudioTooShort') : (inputValue.length === 0 ? t('BTMBARInputPlaceholder') : t('BTMBARInputPlaceholderFilled'))}
                                    onChange={
                                        event => {
                                            if (audioMessageTooShort) {
                                                resetTooShortWarning()
                                            }
                                            if (!validateUri(event.target.value)) {
                                                setInputValue('')
                                                setSpotUri('')
                                                formik.handleChange(event)
                                                if ((event.target.value.includes('www.') || event.target.value.includes('://') || event.target.value.includes('http') || event.target.value.includes('https'))){
                                                    setUrlAttempt(true)
                                                } else {
                                                    setUrlAttempt(false)
                                                }
                                            }
                                        }
                                    }
                                    onFocus={ inputFocused }
                                    value={formik.values.message}
                                />)}
                            </Fragment>)}
                    </div>
                    {(formik.values.message === '' && recordingStopped === false && audioSupported && inputValue.length === 0) || urlAttempt ? (
                        <motion.img whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }} src={recording ? stop : rec} className="Send-Button" alt="Send-Button" onClick={() => {
                            recordButton()
                        }} />
                        ) : (
                        <motion.img whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }} src={send} className="Send-Button" alt="Send-Button"
                        onClick={
                            () => {
                                if (inputValue.length === 0){
                                    window.grecaptcha.execute()
                                } else {
                                    let newUri = 'spotify:track:'
                                    if (spotify.enqueueTrack.uri.uri){
                                        dispatch(enqueueUri(appState.publicId,false,newUri.concat(inputValue)))
                                    } else {
                                        dispatch(enqueueUri(appState.publicId,true,newUri.concat(inputValue),form.locationMeters))
                                    }
                                    dispatch({ type: ADD_NEW_MESSAGE, payload: { spotify: inputValue, distance: form.userSelectedLocation ? form.locationMeters : '0' }})
                                    setInputValue('')
                                    resetPostForm(false)
                                }
                                setUrlAttempt(false)
                            }
                        } />
                )}
            </div>
            {!desktop ?
            distanceSlider && (
                <div className="Message-Location-Container-Mobile">
                    <div className="center-mobile-content">
                        <label className="Message-Location">{t('BTMBARSpeakAfter')}</label>
                        <div className="Slider-Container">
                            <input
                                type="range" min="100"
                                max={ (race.path.length - 100) }
                                value={ form.locationMeters }
                                onChange={ event => dispatch(updateLocationMeters(event.target.value, race.path.measurePoints)) }
                                className="slider"
                            />
                        </div>
                        <label className="Distance-Label">{ locationLength(1) } { race.units === 'kilometers' ? 'km' : 'mi' }</label>
                    </div>
                    <div className="ok-button-mobile-container">
                        <div className="ok-button-mobile" onClick={ handleMobileOKClick }>
                            <label>OK</label>
                        </div>
                    </div>
                </div>
            )
            :
            null}
            <div>
            </div>
            </motion.div>
            <div className={!desktop ? "recaptcha-row-mobile" : "recaptcha-row"} id='recaptcha'>
            </div>
            </>}
        </div>
        }
        </>
    )
}

export default BottomBar;
