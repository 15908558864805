import React from 'react';

interface ErrorProps {
    error: string
}

const ErrorContainer = ( props: ErrorProps ) => {

    return(
        <div className="error-container">
            <label> { props.error } </label>
        </div>
    );
};

export default ErrorContainer;
