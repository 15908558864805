import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import moment from 'moment-timezone';
import { updateTimerData, updateETA } from "../store/race/actions";
let timer: any = null;

const useUpdateRaceTime = () => {
    const raceState = useSelector((state: RootState) => state.race.racestate);
    const raceLength = useSelector((state: RootState) => state.race.info.length);
    const dispatch = useDispatch();

    const setTimer = () => {
        if (timer === null) {
            const now = moment().unix();
            const difference = now - raceState.liveStarted;
            const duration = moment.duration(difference * 1000, 'milliseconds');
            dispatch(updateTimerData(`${duration.hours()}h ${duration.minutes()}m`));
        }
        timer = setInterval(() => {
            const now = moment().unix();
            const difference = now - raceState.liveStarted;
            const duration = moment.duration(difference * 1000, 'milliseconds');
            dispatch(updateTimerData(`${duration.hours()}h ${duration.minutes()}m`));
        }, 60000);
    };

    const calculateEta = (currentLength: number, units: string) => {
        if (raceState.liveEnded !== 0 && raceState.liveEnded !== undefined) {
            const duration = moment(raceState.liveEnded * 1000).local().format('HH:mm');
            const timeOfFinish = `${duration}`;
            dispatch(updateETA(timeOfFinish))
        } else {
            const now = moment().unix();
            const difference = now - raceState.liveStarted;
            const duration = moment.duration(difference * 1000, 'milliseconds');
            const durationInSeconds = duration.minutes() * 60;
            const lengthInMeters = units === 'kilometers' ? currentLength * 1000 : currentLength * 1.61 * 1000;
            const currentSpeed = (lengthInMeters / durationInSeconds) * 3.6;
            const estimatedTimeTillFinishLine = Number(raceLength) / currentSpeed;
            if (estimatedTimeTillFinishLine !== 0 && !isNaN(estimatedTimeTillFinishLine) && estimatedTimeTillFinishLine !== Infinity) {
                const toMilliSeconds = estimatedTimeTillFinishLine * 60 * 60 * 1000; 
                const eta = moment(raceState.liveStarted * 1000).local().add(toMilliSeconds).format('HH:mm');
                dispatch(updateETA(eta));
            }
        }
    };

    const clearTimer = () => {
        clearInterval(timer);
        const difference = raceState.liveEnded - raceState.liveStarted;
        const duration = moment.duration(difference * 1000, 'milliseconds');
        dispatch(updateTimerData(`${duration.hours()}h ${duration.minutes()}m`));
    };

    return {
        setTimer,
        calculateEta,
        clearTimer
    }
};

export default useUpdateRaceTime;
