import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { setUnsupportedBrowserWarning } from './store/app/actions';


const UnsupportedBrowser = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()



    return (
        <div className="main-div">
            <div className="us-browser-container">
                <div>
                </div>
                <div>
                    <h1 className="somebrow-title break-on-n">{t('USBrowserTitle').replace('\\n', '\n')}</h1>
                    <div className="us-browser-help">
                        <label className="somebrow-label">{t('USBrowserHelp1')}</label>
                        <img className="threedots-img" src={require('./img/threeDots.svg')}></img>
                        <label className="somebrow-label break-on-n">{t('USBrowserHelp2').replace('\\n', '\n')}</label>
                    </div>
                </div>
                <label className="continue-Anyway break-on-n" onClick={() => dispatch(setUnsupportedBrowserWarning(false))}>{t('USBrowserContinue').replace('\\n', '\n')}</label>

            </div>
        </div>
    );
};

export default UnsupportedBrowser;
