import React from 'react';
import { RootState } from "../../../src/store/index"
import { useSelector } from "react-redux";

import calendar from '../../../src/svg/ic_calendar.svg'
import clock from '../../../src/svg/ic_clock.svg'
import flag from '../../../src/svg/ic_flag.svg'
import { NONAME } from 'dns';
import ResponseItem from '../../ResponseItem';
import RunnerStatus from '../../RunnerStatus';
import UnitsContainer from '../../UnitsContainer';
import LanguageContainer from '../../LanguageContainer';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import { useTranslation } from 'react-i18next'

interface MoreProps {
    inBaseScene?: boolean
}

const More = (props: MoreProps) => {

    const selectRace = (state: RootState) => state.race
    const selectSpotify = (state: RootState) => state.spotify
    const spotify = useSelector(selectSpotify)
    const race = useSelector(selectRace)
    const responses = useSelector((state: RootState) => state.responses);
    const { width, height } = useWindowDimensions();
    const desktop = (width > 1200);
    const { t, i18n } = useTranslation()

    return (
        <div className={props.inBaseScene ? 'flex-new in-base-width' : 'flex-new'}>
            <div className="more-bg">
                <div className="static-content">
                    <h2>{t('MORERouteLength')}</h2>
                    <div className="row-more">

                    <div className="info-box">
                        <div className="row-5">
                            <label className="val">{race.units === 'kilometers' ? `${race.info.length}km` : `${(Number(race.info.length) * 0.62).toFixed(1)}mi`}</label>

                        </div>

                    </div>
                        <UnitsContainer />


                    </div>

                    <LanguageContainer />
                </div>
                {race.racestate.liveStarted !== 0 && race.racestate.liveStarted !== undefined && desktop ?
                <div className="live-content">
                    <div className="live-separator-line" />
                    <RunnerStatus />
                    <div className="response-container">
                    {responses.responses.length > 0 ?
                            <ul>
                                {responses.responses.map((response: any, id: number) => {
                                    return (
                                        <ResponseItem response={ response } key={ id } />
                                    )
                                })}
                            </ul>
                        :
                        null}
                        </div>
                    <div className="spotify-container">
                    {//spotify track
                        spotify.track ? <iframe src={`https://open.spotify.com/embed/track/${spotify.track.replace('spotify:track:', '')}`} width="100%" height={desktop ? "80" : "90"}allow="encrypted-media" /> : null
                    }
                    </div>
                </div>
                :
                null}
            </div>

        </div>

    )
}

export default More;
