export interface AppState {
    login: boolean;
    dataFetched: boolean;
    unsupportedBrowserWarning: boolean;
    publicId: string
    inputSelected: boolean,
    locationSliderChanged: boolean,
    firstMessageSent: boolean,
}

export const LOGIN_SUCCESS = "login_success";
export const DATA_FETCH_SUCCESS = 'data_fetch_success';
export const INPUT_SELECTED = 'input_selected';
export const LOCATION_SLIDER_CHANGED = 'location_slider_changed';
export const FIRST_MESSAGE_SENT = 'first_message_sent';
export const SET_UNSUPPORTED_BROWSER_WARNING = 'set_unsupported_browser_warning'


interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS
}

interface DataFetchSuccessAction {
    type: typeof DATA_FETCH_SUCCESS
    payload: string
}

interface InputSelectedAction {
    type: typeof INPUT_SELECTED
    payload: boolean
}

interface LocationSliderChangedAction {
    type: typeof LOCATION_SLIDER_CHANGED
    payload: boolean
}

interface FirstMessageSentAction {
    type: typeof FIRST_MESSAGE_SENT
    payload: boolean
}

interface UnsupportedBrowserSetAction {
    type: typeof SET_UNSUPPORTED_BROWSER_WARNING
    payload: boolean
}

export type AppActionTypes = LoginSuccessAction | DataFetchSuccessAction | InputSelectedAction | FirstMessageSentAction | LocationSliderChangedAction | UnsupportedBrowserSetAction;