import { FirebaseRefs } from '../../enums/firebaseRefs'
import { getFirebaseRef } from '../../firebase'
import { PATH_FETCH_SUCCESS, EVENT_FETCH_SUCCESS, RACE_STATE_FETCH_SUCCESS, UPDATE_DURATION, UPDATE_ETA, TOGGLE_UNITS, FETCH_PUBLIC_PEP_POINTS } from './types'
import { DATA_FETCH_SUCCESS, SET_UNSUPPORTED_BROWSER_WARNING } from '../app/types'

let listener: any = null;
let publicPepPointListener: any = null;

function isUnsupportedBrowser() {
    var ua = navigator.userAgent || navigator.vendor;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf('Instagram') > -1);
}

export const fetchRaceData:any = (publicId: string) => {
    return async (dispatch: any) => {
        try {
            const pathRef = getFirebaseRef(FirebaseRefs.Path, publicId)
            const eventRef = getFirebaseRef(FirebaseRefs.Event, publicId)

            const path = await pathRef.once('value')
            const event = await eventRef.once('value')

            if (event.val() === null || event.val().notRace) {
                window.location.replace('https://pepsapp.com/notfound')
            }  else {
                dispatch({ type: SET_UNSUPPORTED_BROWSER_WARNING, payload: isUnsupportedBrowser()})
                dispatch({ type: PATH_FETCH_SUCCESS, payload: path.val() })
                dispatch({ type: EVENT_FETCH_SUCCESS, payload: event.val() })
                dispatch({ type: DATA_FETCH_SUCCESS, payload: publicId })
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }
}

//Listen when the live starts or ends
export const addRaceStateLister: any = (publicId: string) => {
    return async (dispatch: any) => {
        try {
            listener = getFirebaseRef(FirebaseRefs.RaceState, publicId);
            return listener.on('value', (snapshot: any) => {
                dispatch({ type: RACE_STATE_FETCH_SUCCESS, payload: snapshot.val() });
            });
        } catch (error) {
            console.log('addLiveListener error: ', error);
        }
    };
};

export const removeRaceStateListener: any = () => {
    listener.off('value');
};

export const updateTimerData: any = (newDuration: string) => {
    return (dispatch: any) => {
        dispatch({ type: UPDATE_DURATION, payload: { duration: newDuration } });
    };
};

export const updateETA: any = (eta: string) => {
    return (dispatch: any) => {
        dispatch({ type: UPDATE_ETA, payload: { eta: eta }});
    };
};

export const toggleUnits: any = (units: string) => {
    return (dispatch: any) => {
        dispatch({ type: TOGGLE_UNITS, payload: units });
    };
};

export const addPublicPepPointListener: any = (publicId: string) => {
    return async (dispatch: any) => {
        try {
            publicPepPointListener = getFirebaseRef(FirebaseRefs.PublicPepPoints, publicId);
            return publicPepPointListener.on('value', (snapshot: any) => {
                snapshot.forEach((point: any) => {
                    dispatch({ type: FETCH_PUBLIC_PEP_POINTS, payload: point.val() })
                })
            });
        } catch (error) {
            console.log('fetchPublicPepPoints error: ', error);
        }
    };
};

export const removePublicPepPointListener: any = () => {
    publicPepPointListener.off('value');
};
