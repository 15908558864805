import { getFirebaseRef } from "../firebase";
import { FirebaseRefs } from "../enums/firebaseRefs";
import { useDispatch } from 'react-redux';
import useAnimateLiveLocation from "./AnimateLiveLocationHooks";
import { fetchLiveLocationHistory } from "../store/livelocation/actions";

const useUpdateLiveLocation = () => {
    let listener: any = null;
    const dispatch = useDispatch();
    const { setCoordinates } = useAnimateLiveLocation();

    //add a listener which listens new coordinates
    const addLiveLocationListener = async (publicId: string) => {
        try {
            listener = getFirebaseRef(FirebaseRefs.LiveLocation, publicId);
            //Try to get all the points from firebase
            const livelocationPoints = await listener.once('value');

            //If there are coordinates in firebase push them to liveTail redux state
            if (livelocationPoints.hasChildren()) {
                const points: number[][] = [];
                livelocationPoints.forEach((point: any) => {
                    const coordinates = point.val().coordinates;
                    const longLat = [coordinates.longitude, coordinates.latitude];
                    points.push(longLat);
                });

                dispatch(fetchLiveLocationHistory(points));

                /* After attach the listener which starts to push new coordinates to setCoordinates function
                    which starts eventually the animation and updates the redux state for liveTail and locationArrow
                */ 
                return listener.limitToLast(1).on('child_added', (snapshot: any) => {
                    setCoordinates(snapshot.val());
                });
            } else {
                return listener.limitToLast(1).on('child_added', (snapshot: any) => {
                    setCoordinates(snapshot.val());
                });
            }

        } catch (error) {
            console.log('addLiveLocationListener error: ', error);
        }
    };

    const removeLiveLocationListener = () => {
        listener.off('child_added');
    };

    return {
        addLiveLocationListener,
        removeLiveLocationListener,
    }
};

export default useUpdateLiveLocation;
