import React, { useState, useEffect } from 'react';

import ClipboardData from "./ClipboardData"
import useWindowDimensions from './helpers/useWindowDimensions';

const Fallback = (props: any) => {

    useEffect(() => {
        if (props.reset) {
            props.setInputValue('')
            props.setSendableUri('');
            props.setAllowSend(false);
            props.setCheckPressed(false)
        }
    });

    return (
        <>
            {/*<input type="text" onChange={props.handleChange} placeholder={'click and paste here'} value={props.inputValue} />*/}    
            <button onClick={props.handleClose} style={{marginRight: 5}} ><span>{'OK!'}</span></button>
            {/*<button onClick={props.checkInput} style={{marginLeft: 5}} ><span>{'Check'}</span></button>*/}
        </>
    )
}

const SpotifyModal = (props: any) => {

    const [inputValue, setInputValue] = useState('');

    const [sendableUri, setSendableUri] = useState('');

    const [allowSend, setAllowSend] = useState(false);

    const [checkPressed, setCheckPressed] = useState(false);

    const checkInput = () => {
        setCheckPressed(true);
        setAllowSend(validateUri())
    };

    const closeSpotifyModal = (event: any) => {
        if (event.target.className === 'spotify-modal') {
            props.handleClose();
        }
    }
    
    useEffect(() => {
        window.addEventListener('click', closeSpotifyModal);
        return () => {
            window.removeEventListener('click', closeSpotifyModal);
        }
    });

    const handleChange = (e: any) => setInputValue(e.target.value);

    const validateUri = () => {
        if (!inputValue.includes('https://open.spotify.com/track/') || inputValue.split('https://open.spotify.com/track/')[1].length === 0) {
            return false;
        }

        let uri = inputValue.split('https://open.spotify.com/track/')[1];
        uri = uri.split('?')[0];
        const pattern = /^[a-zA-Z0-9\s]*$/;
        if (pattern.exec(uri)) {
            setSendableUri(uri);
            return true;
        }
        return false;
    }
        
    const { width } = useWindowDimensions();
    const desktop = (width > 1200)

    return (
        <div className="spotify-modal">
            <div className={desktop ? "spotify-modal-content desktop-modal" : "spotify-modal-content"}>
                {
                    allowSend ?
                    <>
                        <iframe src={`https://open.spotify.com/embed/track/${sendableUri}`} width="300" height="380" allow="encrypted-media" />
                        <ClipboardData 
                            sendUri={sendableUri} 
                            fallback={<Fallback 
                                handleChange={handleChange} 
                                checkInput={checkInput} 
                                setInputValue={setInputValue} 
                                setCheckPressed={setCheckPressed} 
                                setAllowSend={setAllowSend} 
                                setSendableUri={setSendableUri} 
                                reset={true}
                                inputValue={inputValue}
                                handleClose={props.handleClose} />} 
                            handleClose={props.handleClose} />
                    </>
                    : 
                    !allowSend  && checkPressed
                    ?
                    <>
                        <p style={{overflowWrap: 'break-word'}}>The link was invalid. A track link copied from spotify applications example: https://open.spotify.com/track/3AT07E7tLLthDh3Bb5yT7A</p>
                        <button onClick={() => setCheckPressed(false)} ><span>{'Got it'}</span></button>
                    </>
                    :
                    <>
                        <img src={require('./img/spotifyguide.gif')} alt="spotify sharelink instructions" className="spotify-help-gif" />
                        <p>Copy a song link from Spotify to the message field to add songs to the runner's queue!</p>
                        <Fallback handleClose={props.handleClose} handleChange={handleChange} checkInput={checkInput} inputValue={inputValue}/>
                    </>
                }
            </div>
        </div>

    )
}

export default SpotifyModal;