export const FETCH_SPOTIFY_TRACK = 'fetch_spotify_track';
export const FAILED_FETCH_SPOTIFY_TRACK = 'failed_fetch_spotify_track';
export const FETCH_SPOTIFY_ENQUEUETRACK_STATE = 'fetch_spotify_enqueuetrack_state';
export const FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE = 'failed_fetch_spotify_enqueuetrack_state';
export const FETCH_SPOTIFY_ENQUEUETRACK_LIVE = 'fetch_spotify_enqueuetrack_live';
export const FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE = 'failed_fetch_spotify_enqueuetrack_live';

export interface SpotifyState {
    track: any,
    enqueueTrack: {
        state: {
            connected: boolean,
            enqueueTrack: boolean
        },
        uri: {
            uri: any
        }
    }
}

interface SpotifyTrackFetchSuccessAction {
    type: typeof FETCH_SPOTIFY_TRACK
    payload: any
};

interface SpotifyTrackFetchFailedAction {
    type: typeof FAILED_FETCH_SPOTIFY_TRACK
    payload: any
};

interface SpotifyEnqueueTrackStateFetchSuccessAction {
    type: typeof FETCH_SPOTIFY_ENQUEUETRACK_STATE
    payload: any
}

interface SpotifyEnqueueTrackStateFetchFailedAction {
    type: typeof FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE
    payload: any
}

interface SpotifyEnqueueTrackLiveFetchSuccessAction {
    type: typeof FETCH_SPOTIFY_ENQUEUETRACK_LIVE
    payload: any
}

interface SpotifyEnqueueTrackLiveFetchFailedAction {
    type: typeof FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE
    payload: any
}

export type SpotifyActionTypes = SpotifyTrackFetchSuccessAction | SpotifyTrackFetchFailedAction | SpotifyEnqueueTrackStateFetchSuccessAction | SpotifyEnqueueTrackStateFetchFailedAction | SpotifyEnqueueTrackLiveFetchSuccessAction | SpotifyEnqueueTrackLiveFetchFailedAction;