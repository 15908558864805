import React, { useState } from 'react';
import unitsActive from './svg/units_active.svg';
import unitsInactive from './svg/units_inactive.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store';
import { toggleUnits } from './store/race/actions';
import { useTranslation } from 'react-i18next'

const LanguageContainer = () => {
    const units = useSelector((state: RootState) => state.race.units);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()

    interface Locale {
        image: string,
        code: string,
        displayName: string
    }

    const locales: Locale[] = [
        {image: 'fi', code: 'fi', displayName: 'Finnish'},
        {image: 'en', code: 'en', displayName: 'English'}
        ]

    const changeSelect = (e:any) => {
        let value = e.target.value
        console.log(i18n.language)
        i18n.changeLanguage(value)
    }

    const currentLanguage = i18n.language

    return (
        <div className="units-container">
            <h2>{t('LANGUAGE')}</h2>

            <select onChange={(e) => changeSelect(e)} className={'language-select ' + currentLanguage} defaultValue={currentLanguage}>
            {locales.map(locale => {
                return <option value={locale.code} key={locale.code}>{locale.displayName}</option>
            })}
            </select>

        </div>
    );
};

export default LanguageContainer;
