import React from 'react';
import './Loading.scss'
import { motion } from "framer-motion"
import loader from '../../../src/svg/loader.svg'

const Loading = () => {

    return (
        <div className="loading-container">

            <div className="box">
                <motion.div
                    animate={{ rotate: 360 }}
                    transition={{
                        loop: Infinity,
                        ease: "linear",
                        duration: 2
                    }}

                >
                    <img src={loader} className="box"></img>

                </motion.div>
            </div>
            <label className="refresh-label">If nothing happens, hit refresh.</label>

        </div>
    )
}

export default Loading