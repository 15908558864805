export interface LiveLocationState {
    locationArrow: {
        type: 'Feature',
        properties: {
            bearing: number,
        },
        geometry: {
            type: 'Point'
            coordinates: number[],
        },
    },
    liveTail: {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'LineString',
            coordinates: number[][],
        },
    },
    length: number,
}

interface UpdateLocationArrowResponse {
    coordinates: number[]
    bearing: number,
};

interface UpdateLiveTailingResponse {
    coordinates: number[];
};

interface FetchRouteHistoryResponse {
    coordinates: number[][];
};

export const UPDATE_LOCATION_ARROW = 'update_location_arrow';
export const UPDATE_LIVE_TAILING = 'update_live_tailing';
export const FETCH_ROUTE_HISTORY = 'fetch_route_history';
export const FETCH_LENGTH = 'fetch_length';

interface UpdateLocationArrowSuccessAction {
    type: typeof UPDATE_LOCATION_ARROW;
    payload: UpdateLocationArrowResponse;
};

interface UpdateLiveTailingSuccessAction {
    type: typeof UPDATE_LIVE_TAILING;
    payload: UpdateLiveTailingResponse;
};

interface FetchRouteHistorySuccessAction {
    type: typeof FETCH_ROUTE_HISTORY
    payload: FetchRouteHistoryResponse
};

interface FetchLengthSuccessAction {
    type: typeof FETCH_LENGTH
    payload: number
}

export type LiveLocationTypes = UpdateLocationArrowSuccessAction | UpdateLiveTailingSuccessAction | FetchRouteHistorySuccessAction | FetchLengthSuccessAction;
