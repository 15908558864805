import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import useUpdateRaceTime from '../src/hooks/UpdateRaceTimeHooks';
import { useTranslation } from 'react-i18next'

const RunnerStatus = () => {
    const duration = useSelector((state: RootState) => state.race.racetime.duration);
    const raceState = useSelector((state: RootState) => state.race.racestate);
    const eta = useSelector((state: RootState) => state.race.racetime.eta);
    const units = useSelector((state: RootState) => state.race.units);
    const distance = useSelector((state: RootState) => state.liveLocation.length);
    const { calculateEta } = useUpdateRaceTime();
    const { t, i18n } = useTranslation()

    useEffect(() => {
        calculateEta(Number(distance), units);
    }, [distance]);

    return (
        <>
            <h2>{t('RSTATUSTitle')}</h2>
            <div className={"runner-status-container"}>
            <div className="runner-status-column">
                <label className="header-label">{t('RSTATUSTime')}</label>
                <label> { duration !== '' ? duration : '0h 0m' } </label>
            </div>
            <div className="runner-status-column">
                <label className="header-label">{t('RSTATUSDistance')}</label>
                <label> { distance !== 0 ? units === 'kilometers' ? `${distance}km` : `${distance}mi` : units === 'kilometers' ? '0.00km' : '0.00mi'} </label>
            </div>
            <div className="runner-status-column">
                <label className="header-label"> {raceState.liveEnded !== 0 && raceState.liveEnded !== undefined ? t('RSTATUSFinishTime') : t('RSTATUSETA')} </label>
                <label> { eta ? eta : '...' } </label>
            </div>
        </div>
        </>
    );
};

export default RunnerStatus;
