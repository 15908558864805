import * as turf from '@turf/helpers'

interface PublicPepPoint {
    distanceLocation: number,
    speakOnEnter: number,
    spoken: boolean,
    locationOnMap: turf.Feature<turf.Point>
}

export interface RaceState {
    path: {
        encoded: string
        length: number,
        decoded: turf.Position[],
        decoded_up: turf.Position[],
        startPoint: turf.Feature<turf.Point>,
        endPoint: turf.Feature<turf.Point>,
        measurePoints: turf.Feature<turf.Point, turf.Properties>[],
    },
    event: {
        name: string | null,
        start: number,
        tracking: boolean,
        notRace: boolean,
    },
    info: {
        length: string,
        dateFormatted: string
    },
    racestate: {
        liveStarted: number,
        liveEnded: number
    },
    racetime: {
        duration: string,
        eta: string,
    },
    publicPepPoints: PublicPepPoint[],
    units: 'kilometers' | 'miles',
}

interface PathFetchResponse {
    encoded: string
    length: number
}
interface EventFetchReponse {
    description: string,
    end: number,
    name: string,
    notRace: boolean,
    start: number,
    tracking: boolean
}

interface RaceStateResponse {
    liveStarted: number,
    liveEnded: number
};

interface UpdateDurationResponse {
    duration: string,
};

interface UpdateEtaResponse {
    eta: string,
};

interface FetchPublicPepPointsResponse {
    distanceLocation: number,
    speakOnEnter: number,
    spoken: boolean,
};

export const LOGIN_SUCCESS = 'login_success'
export const PATH_FETCH_SUCCESS = 'path_fetch_success'
export const EVENT_FETCH_SUCCESS = 'event_fetch_success'
export const RACE_STATE_FETCH_SUCCESS = 'race_state_fetch_success'
export const UPDATE_DURATION = 'update_duration'
export const UPDATE_ETA = 'update_eta'
export const TOGGLE_UNITS = 'toggle_units'
export const FETCH_PUBLIC_PEP_POINTS = 'fetch_public_pep_points'

interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS
}

interface PathFetchSuccessAction {
    type: typeof PATH_FETCH_SUCCESS;
    payload: PathFetchResponse;
}

interface EventFetchSuccessAction {
    type: typeof EVENT_FETCH_SUCCESS;
    payload: EventFetchReponse;
}

interface RaceStateFetchSuccessAction {
    type: typeof RACE_STATE_FETCH_SUCCESS
    payload: RaceStateResponse
}

interface UpdateDurationSuccessAction  {
    type: typeof UPDATE_DURATION
    payload: UpdateDurationResponse
}

interface UpdateEtaSuccessAction {
    type: typeof UPDATE_ETA
    payload: UpdateEtaResponse
}

interface ToggleUnitsSuccessAction {
    type: typeof TOGGLE_UNITS
    payload: 'kilometers' | 'miles'
}

interface FetchPublicPepPointsSuccessAction {
    type: typeof FETCH_PUBLIC_PEP_POINTS
    payload: FetchPublicPepPointsResponse
}

export type RaceActionTypes = LoginSuccessAction | PathFetchSuccessAction | EventFetchSuccessAction | RaceStateFetchSuccessAction | UpdateDurationSuccessAction | UpdateEtaSuccessAction | ToggleUnitsSuccessAction | FetchPublicPepPointsSuccessAction;