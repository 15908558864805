import React, {PureComponent} from 'react';

import position from '../svg/position.svg'
export default class Pin extends PureComponent {
  render() {
    const {size = 20} = this.props;

    return (
      <img src={position} alt="Posi"></img>
      );
  }
}
