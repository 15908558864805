import React, { useState } from 'react';

import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../src/store/index"

import { enqueueUri } from './store/spotify/actions';

const ClipboardData = (props: any) => {

    const dispatch = useDispatch()
    
    const selectState = (state: RootState) => state;
    const { spotify, appState } = useSelector(selectState);
    const [newUri, setNewUri] = useState('spotify:track:');
    const [reset, setReset] = useState(false);

    const sendLinkToRunner = (uri: any) => {
        if (spotify.enqueueTrack.uri.uri){
            dispatch(enqueueUri(appState.publicId,false,newUri.concat(uri)))
        } else {
            dispatch(enqueueUri(appState.publicId,true,newUri.concat(uri)))
        }
        
        props.handleClose();
    }

    if (!reset){
        return (
            <div>
                <button className='spotify-send' style={{marginRight: 5}} onClick={() => setReset(true)}><span>Back</span></button>
                <button className='spotify-send' style={{marginLeft: 5}} onClick={() => sendLinkToRunner(props.sendUri)}><span>Send!</span></button>
            </div>
        );
    } else {
        return props.fallback;
    }
}

export default ClipboardData;