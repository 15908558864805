import { FETCH_RESPONSES, FAILED_FETCH_RESPONSES, ResponseActionTypes, ResponseState } from './types';

const initialState: ResponseState = {
    responses: [],
}

export const responsesReducer = (state = initialState, action: ResponseActionTypes) => {
    switch (action.type) {
        case FETCH_RESPONSES:
            return {
                ...state,
                responses: [...state.responses, action.payload]
            };
        case FAILED_FETCH_RESPONSES:
            console.log('Error: ', action.payload);
        default:
            return state;
    };
};
