import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Prismic from 'prismic-javascript'

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

async function getSingleLanguage(language:string) {
    console.log('haetaan ' + language)
    var apiEndpoint = "https://pepsapp.prismic.io/api/v2";
    const api = await Prismic.api(apiEndpoint)
    const isFetchableFi = (language === '/locales/fi/translation.json')
    const FI_DOC_ID = 'X18yzBAAACMAWa9u'
    const EN_DOC_ID = 'X18zORAAACUAWbFl'
    const document = await api.query(Prismic.Predicates.at('document.id', isFetchableFi ? FI_DOC_ID : EN_DOC_ID), { lang: '*' })
    return document.results[0].data
}

const apiOptions = {
     backend: {
        request: async (
            _options: object,
            _url: string,
            _payload: object,
            callback: (err?: object, res?: object) => never,
        ): Promise<never> => {
             const data = await getSingleLanguage(_url)
             console.log(data)
            callback(undefined, { status: 'ok', data: data })
        },
    }
}

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .use(HttpApi).init(apiOptions)



export default i18n;