export enum FirebaseRefs {
    Event,
    Path,
    Responses,
    LiveLocation,
    Track,
    EnqueueTrackPreRace,
    EnqueueTrackState,
    EnqueueTrackLive,
    RaceState,
    PublicPepPoints,
}