import { UPDATE_LOCATION_METERS, RESET_FORM, FormActionTypes } from './types'
import { REQUEST_START, REQUEST_SUCCESS, REQUEST_ERROR } from '../messages/types'
import { ADD_NEW_MESSAGE } from '../messages/types'
import axios from "axios"
import * as turf from '@turf/helpers'
import { RootState } from "../../../src/store/index"
import { TextMessageAdvance, TextMessageLive } from "../../../src/interfaces/api"
import { resetSender } from '../messages/actions'

export const updateLocationMetersRand = (raceLength: number) => {
    return (dispatch: any, getState: () => RootState) => {
        const measurePoints = getState().race.path.measurePoints;
        const randomDistance = Math.floor(Math.random() * (raceLength - 100));
        dispatch(updateLocationMeters(String(randomDistance), measurePoints))
    }
}

export const updateLocationMeters = (val: string, measurePoints: turf.Feature<turf.Point, turf.Properties>[]): FormActionTypes => {

    //Check if there is a route. If there is not, just update the locationMeters value. Otherwise get the position from cache
    if (measurePoints.length > 0) {
        const position = measurePoints[Number(val)].geometry.coordinates;

        return ({ type: UPDATE_LOCATION_METERS, payload: { position: position, locationMeters: val }});
    }

    return ({ type: UPDATE_LOCATION_METERS, payload: { locationMeters: val }})
}

export const sendMessage = (message: string, location: Array<number>, captcha: any, live: boolean) => {
    return (dispatch: any, getState: () => RootState) => {
        window.grecaptcha.reset();
        dispatch({ type: REQUEST_START })
        const senderName = localStorage.getItem('displayName');

        //Getting locationMeters from redux state here because otherwise if the user has'nt set them 
        //And we need to randomize the location, it will set it to 0
        const distance = Number(getState().form.locationMeters);
        let data;
        
        if (live) {
            const liveData: TextMessageLive = {
                message: message,
                sender: senderName,
                captcha: captcha,
            };

            data = liveData;
        } else {
            const advanceData: TextMessageAdvance = {
                sender: senderName,
                message: message,
                captcha: captcha,
                distanceMeters: distance
            };

            data = advanceData;
        };
        
        fetch(`https://us-central1-racepeps.cloudfunctions.net/friends/${getState().appState.publicId}/text/${live ? 'live' : 'advance'}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: REQUEST_SUCCESS })
                    const newMessage = { message, distance, location }
                    dispatch({ type: ADD_NEW_MESSAGE, payload: newMessage})
                } else {
                    dispatch({ type: REQUEST_ERROR, payload: response.statusText });
                    setTimeout(() => {
                        dispatch(resetSender());
                    }, 2000);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                dispatch({ type: REQUEST_ERROR, payload: 'Something went wrong' })
            });
    }
}

export const sendVoiceMessage = (message: any, location: Array<number>, voiceMessageTime: string, captcha: any, live: boolean) => {
    return (dispatch: any, getState: () => RootState) => {
        window.grecaptcha.reset();
        dispatch({ type: REQUEST_START })
        let POST_URL = `https://us-central1-racepeps.cloudfunctions.net/friends/${getState().appState.publicId}/audio/${live ? 'live' : 'advance'}`

        var formData = new FormData();
        const senderName = localStorage.getItem('displayName');
        const distance = Number(getState().form.locationMeters);

        formData.append("sender", senderName ? senderName : '')
        if (!live) {
            formData.append("distanceMeters", String(distance)) //because formdata is always string
        }
        formData.append("msg", message) //voicemessage
        formData.append('captcha', captcha)

        axios({
            method: 'post',
            url: POST_URL,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(function (response) {
                message = `Audio (${voiceMessageTime})`
                const newMessage = { message, distance, location }
                dispatch({ type: REQUEST_SUCCESS })
                dispatch({ type: ADD_NEW_MESSAGE, payload: newMessage })
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

    }
}

export const resetForm = (): FormActionTypes => {
    return { type: RESET_FORM }
}