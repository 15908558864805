import React, { PureComponent } from 'react';
import publicPepPointImage from '../svg/racepep_blue.svg'

export default class PublicPepPoint extends PureComponent {

    render() {
        return (
            <img width={ 35 } height={ 35 } src={ publicPepPointImage } alt="PublicPepPoint Marker" />
        );
    };
};
