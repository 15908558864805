import { SpotifyState, SpotifyActionTypes, FETCH_SPOTIFY_TRACK, FAILED_FETCH_SPOTIFY_TRACK, FETCH_SPOTIFY_ENQUEUETRACK_STATE, FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE, FETCH_SPOTIFY_ENQUEUETRACK_LIVE, FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE } from "./types";

const initialState: SpotifyState = {
    track: null,
    enqueueTrack: {
        state: {
            connected: false,
            enqueueTrack: false
        },
        uri: {
            uri: null
        }
    }
};

export const spotifyReducer = (state: SpotifyState = initialState, action: SpotifyActionTypes): SpotifyState => {
    switch (action.type) {
        case FETCH_SPOTIFY_TRACK:
            return {...state, track: action.payload};
        case FAILED_FETCH_SPOTIFY_TRACK:
            console.log('SpotifyTrack unavailable: ', action.payload)
            return {...state, track: action.payload};
        case FETCH_SPOTIFY_ENQUEUETRACK_STATE:
            return {
                track: state.track, 
                enqueueTrack: {
                    state: action.payload,
                    uri: state.enqueueTrack.uri
                }
            }
        case FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE:
            console.log('SpotifyEnqueueTrackState unavailable: ', action.payload)
            return {
                track: state.track, 
                enqueueTrack: {
                    state: action.payload,
                    uri: state.enqueueTrack.uri
                }
            }
        case FETCH_SPOTIFY_ENQUEUETRACK_LIVE:
            return {
                track: state.track, 
                enqueueTrack: {
                    state: state.enqueueTrack.state,
                    uri: action.payload
                }
            }
        case FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE:
            console.log('SpotifyEnqueueTrackLive unavailable: ', action.payload)
            return {
                track: state.track, 
                enqueueTrack: {
                    state: state.enqueueTrack.state,
                    uri: action.payload
                }
            }
        default:
            return state;
    };
};