import React from 'react';
import ReactDOM from 'react-dom';
import {
    MemoryRouter as Router,
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import './scss/styles.scss';
import { store } from "./store";
import { firebaseAuth } from '../src/store/app/actions'
import { fetchRaceData } from '../src/store/race/actions'
import { getFirebaseRef } from './firebase'
import { FirebaseRefs } from './enums/firebaseRefs'
import App from './App'
import './i18n';
const pathRef = getFirebaseRef(FirebaseRefs.Path, '-Ffeffe') //this is to initialize firebase, todo refactor :)


store.dispatch(firebaseAuth())

const app = (
    <Provider store={store}>
        <Router initialEntries={['/main']}>
            <App />
        </Router>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
