// Describing the shape of the system's slice of state
export interface MessageState {
    sender: {
        sending: boolean,
        sent: boolean,
        error: boolean,
        errorText: string,
    }
    messages: any[]
}

// Describing the different ACTION NAMES available
export const REQUEST_SUCCESS = 'request_success'
export const REQUEST_ERROR = 'request_error'
export const REQUEST_START = 'request_start'
export const RESET_SENDER = 'reset_sender'
export const ADD_NEW_MESSAGE = 'add_new_message'

interface RequestSuccessAction {
    type: typeof REQUEST_SUCCESS
}
interface RequestErrorAction {
    type: typeof REQUEST_ERROR
    payload: string,
}
interface RequestStartAction {
    type: typeof REQUEST_START
}
interface ResetSenderAction {
    type: typeof RESET_SENDER
}
interface AddNewMessageAction {
    type: typeof ADD_NEW_MESSAGE
    payload: any
}

interface MessageAction {
    type: any;
    payload: any | null;
}


export type MessageActionTypes = RequestSuccessAction | RequestErrorAction | RequestStartAction | ResetSenderAction | AddNewMessageAction