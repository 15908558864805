import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";


import { appReducer } from '../store/app/reducers'
import { raceReducer } from '../store/race/reducers'
import { formReducer } from '../store/form/reducers'
import { messageReducer } from '../store/messages/reducers'
import { responsesReducer } from '../store/responses/reducers';
import { liveLocationReducer } from './livelocation/reducers';
import { spotifyReducer } from './spotify/reducers';


const rootReducer = combineReducers({
    appState: appReducer,
    race: raceReducer,
    form: formReducer,
    message: messageReducer,
    responses: responsesReducer,
    liveLocation: liveLocationReducer,
    spotify: spotifyReducer,
})

export type RootState = ReturnType<typeof rootReducer>;

function configureStore() {
    const middlewares = [thunkMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    return createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer)
    );
}

export const store = configureStore();
