import React, { useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, { Source, Layer, Marker } from 'react-map-gl';
import WebMercatorViewport, { Bounds } from 'viewport-mercator-project';
import '../../../src/scss/styles.scss';
import { useSelector } from "react-redux";

import Pin from '../../../src/helpers/pin';
import { RootState } from "../../../src/store/index";
import * as turf from '@turf/helpers'
import bbox from '@turf/bbox';
import PublicPepPoint from '../../helpers/PublicPepPoint';
import useWindowDimensions from '../../helpers/useWindowDimensions';
var polyline = require('@mapbox/polyline');

/* function getViewport(path:any) {
  const viewport = new WebMercatorViewport({ width: 1000, height: 1000 });
  var line = turf.lineString(path)
  var b = bbox(line);
  var bounds: Bounds = [[b[0], b[1]], [b[2], b[3]]]
  let vp = viewport.fitBounds(bounds)
  console.log(`Viewport: latitude: ${vp.latitude}, longitude: ${vp.longitude}, zoom: ${vp.zoom}`)
  return {
    latitude: vp.latitude,
    longitude: vp.longitude,
    zoom: vp.zoom
  }
}; */

const Map = () => {
  const selectState = (state: RootState) => state

  const { race, form, liveLocation, responses } = useSelector(selectState)
  const selectSpotify = (state: RootState) => state.spotify
  const spotify = useSelector(selectSpotify)
  const { width, height } = useWindowDimensions();
  const desktop = (width > 1200);

  const getViewPort = () => {
    if (race.path.decoded_up.length > 0) {
      const viewport = new WebMercatorViewport({ width: 1000, height: 1000 });
      var line = turf.lineString(race.path.decoded_up)
      var b = bbox(line);
      var bounds: Bounds = [[b[0], b[1]], [b[2], b[3]]]
      let vp = viewport.fitBounds(bounds)
      console.log(`VP: ${vp.longitude}, ${vp.latitude}`)
      return {
        latitude: vp.latitude,
        longitude: vp.longitude,
        zoom: vp.zoom
      }
    } else {
      const lastKnowLocation = liveLocation.liveTail.geometry.coordinates[liveLocation.liveTail.geometry.coordinates.length - 1];
      return {
        latitude: lastKnowLocation ? lastKnowLocation[1] : 0,
        longitude: lastKnowLocation ? lastKnowLocation[0] : 0,
        zoom: lastKnowLocation ? 14 : 1,
      }
    }
  }

  const [viewport, setViewport] = useState(getViewPort());

   return (
     <div className="MapContainer">

          <ReactMapGL
            width={'100%'}
            height={'100%'}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            zoom={viewport.zoom}
            onViewportChange={setViewport}
            mapboxApiAccessToken={'pk.eyJ1IjoibWlra29hdGEiLCJhIjoiY2l5Y3V5czBjMDA4ZzJ3cXBibXZhaDkzciJ9.F-rRrrNLnB0MfKtcosgmxA'}
            mapStyle={'mapbox://styles/mikkoata/ckeihro9z4seg19unjgkzuoy5'}
       >
         {race.path.encoded ?
         <Source id="my-data" type="geojson" lineMetrics={true} data={ polyline.toGeoJSON(race.path.encoded) }>
           <Layer
             id="point"
             type="line"
             layout={{
              "line-join": "round",
              "line-cap": "round"
              }}
             paint={{
              'line-width': 8,
              'line-gradient': [
                  'interpolate',
                  ['linear'],
                  ['line-progress'],
                  0, "#245eff",
                  1, "#0ebf00"
              ]}}
            />
         </Source>
         :
         null}
         <Source type='geojson' data={liveLocation.liveTail}>
           <Layer
             type='line'
             paint={{
               'line-width': 8,
               'line-color': '#2c8ad1'
             }}
           />
         </Source>
         <Source type='geojson' data={ race.path.startPoint }>
           <Layer
            type='symbol'
            layout={{
              "icon-image": "startLine",
              "icon-size": 0.8,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true
            }}
            paint={{}}
           />
         </Source>
         <Source type='geojson' data={ race.path.endPoint }>
           <Layer
            type='symbol'
            layout={{
              "icon-image": "finishline",
              "icon-size": 0.8,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true
            }}
            paint={{}}
           />
         </Source>
         <Source type='geojson' data={ liveLocation.locationArrow }>
             <Layer
              type='symbol'
              layout={{
                'icon-image': 'runnerlocation',
                'icon-rotate': ['get', 'bearing'],
                'icon-rotation-alignment': 'map',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-size': 0.6
              }}
              paint={{}}
             />
         </Source>

         {race.publicPepPoints.map((point, id) => {
           return (
             <Marker
              longitude={point.locationOnMap.geometry.coordinates[0]}
              latitude={point.locationOnMap.geometry.coordinates[1]}
              offsetLeft={ -10 }
              offsetTop={ -35 }
              key={ id }>
               <PublicPepPoint />
             </Marker>
           );
          })}
          {responses.responses.map((response: any, id: any) => {
            if (response.type !== 'audio') {
              return (
                <Marker
                  longitude={response.position.longitude}
                  latitude={response.position.latitude}
                  key={id}
                  offsetTop={ -15 }
                  offsetLeft={ -15 }
                >
                  <div className="emojis-on-map-container">
                    <label> { response.response } </label>
                  </div>
                </Marker>
              )
            }
            if (response.type === 'audio' && response.hasOwnProperty('position')) {
              return (
                <Marker
                  longitude={response.position.longitude}
                  latitude={response.position.latitude}
                  key={id}
                  offsetTop={-15}
                  offsetLeft={-15}
                >
                  <div className="emojis-on-map-container">
                    <label> {'🔊'} </label>
                  </div>
                </Marker>
              )
            }
          })}
             {form.position &&
             <Marker
               longitude={form.position![0]}
               latitude={form.position![1]}
               offsetLeft={ -20 }
               offsetTop={ -20 }
             >
              <Pin size={20} />
             </Marker>
             }
       </ReactMapGL>
      {//spotify track
        spotify.track && !desktop ? <iframe src={`https://open.spotify.com/embed/track/${spotify.track.replace('spotify:track:', '')}`} width="100%" height={desktop ? "80" : "90"}allow="encrypted-media" /> : null
      }
     </div>
   )

}
export default Map;