import React from 'react';
import moment from 'moment';
import playVoiceMessageButton from './svg/ic_playvoicemessage.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { RootState } from './store';
import { useSelector } from 'react-redux';

interface Props {
    response: {
        response: string,
        timestamp: string,
        type: string,
        duration: number,
    }
}

const ResponseItem = (props: Props) => {

    const { t, i18n } = useTranslation()
    const selectPublicId = (state: RootState) => state.appState.publicId
    const publicId = useSelector(selectPublicId)


    const playAudioMessage = async () => {
        const audioUrl = `https://storage.googleapis.com/voicemessages/${publicId}/${props.response.response}`;
        const audioFile = new Audio(audioUrl);
        console.log('AudioUrl: ', audioUrl);
        console.log('AudioFile: ', audioFile);
        try {
            await audioFile.play();
        } catch (error) {
            console.log('Error playing audioFile: ', error);
        }
    }

    return (
        <li>
            <div className="response-row">
                {props.response.type === 'audio' ?
                <div className="audio-response-container">
                    <label className="audio-label-text">{t('MOREResItemAudio')} ({ props.response.duration < 10 ? `00:0${props.response.duration}` : `00:${props.response.duration}` })</label>
                    <img src={ playVoiceMessageButton } alt="Play voicemessage button" onClick={ () => playAudioMessage() } />
                </div>
                :
                <label className="emoji-text"> { props.response.response } </label>}
                <label className="timestamp-label-text"> { moment(props.response.timestamp).format('LT') } </label>
            </div>
        </li>
    );
};

export default ResponseItem;
