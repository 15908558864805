import { LOGIN_SUCCESS, AppActionTypes, INPUT_SELECTED, FIRST_MESSAGE_SENT, LOCATION_SLIDER_CHANGED, SET_UNSUPPORTED_BROWSER_WARNING } from './types'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const firebaseAuth:any = () => {
    return async (dispatch:any) => {
        const login = await firebase.auth().signInAnonymously()
        console.log(login)
        dispatch({ type: LOGIN_SUCCESS })
    }
}

export const inputSelectedForTheFirstTime: any = () => {
    return (dispatch: any) => {
        dispatch({ type: INPUT_SELECTED, payload: true });
    }
};

export const toggleLocationSliderChanged: any = () => {
    return (dispatch: any) => {
        dispatch({ type: LOCATION_SLIDER_CHANGED, payload: true });
    }
};

export const firstTextMessageSent: any = () => {
    return (dispatch: any) => {
        dispatch({ type: FIRST_MESSAGE_SENT, payload: true });
    }
}

export const setUnsupportedBrowserWarning: any = (value:boolean) => {
    return (dispatch: any) => {
        dispatch({ type: SET_UNSUPPORTED_BROWSER_WARNING, payload: value})
    }
}
