import { PATH_FETCH_SUCCESS, EVENT_FETCH_SUCCESS, RaceActionTypes, RaceState, RACE_STATE_FETCH_SUCCESS, UPDATE_DURATION, UPDATE_ETA, TOGGLE_UNITS, FETCH_PUBLIC_PEP_POINTS } from "./types";
import moment from 'moment-timezone'
import * as turf from '@turf/helpers'
import along from "@turf/along";
var polyline = require('@mapbox/polyline');

const initialState: RaceState = {
    path: {
        encoded: '',
        length: 0,
        decoded: [],
        decoded_up: [],
        startPoint: {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: [],
            },
        },
        endPoint: {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: [],
            },
        },
        measurePoints: [],
    },
    event: {
        name: null,
        start: 0,
        tracking: false,
        notRace: false,
    },
    info: {
        length: '',
        dateFormatted: ''
    },
    racestate: {
        liveStarted: 0,
        liveEnded: 0
    },
    racetime: {
        duration: '',
        eta: '',
    },
    publicPepPoints: [],
    units: 'kilometers',
};

export function raceReducer(
    state = initialState,
    action: RaceActionTypes
): RaceState {
    switch (action.type) {
        case PATH_FETCH_SUCCESS:
            let decoded_up:any = []
            let decoded: any;
            let startPoint: number[] = []
            let endPoint: number[] = []
            const alongCoordinates = [];
            if (action.payload.encoded) {
                decoded = polyline.decode(action.payload.encoded)
                decoded.forEach((element:turf.Position) => {
                    let ele = [element[1], element[0]]
                    decoded_up.push(ele)
                });
                startPoint = decoded_up[0];
                endPoint = decoded_up[decoded_up.length - 1];
            }
            const length = Number(action.payload.length.toFixed()) //as meters
            const lengthKm = (length / 1000).toFixed(1)

            /**
             * Get linestring of the route and calculate coordinates for every meter.
             *  This is to enchance the performance of the location slider
             */
            if (decoded_up.length !== 0) {
                const lineString = turf.lineString(decoded_up);
    
                for (let i = 0; i < length; i++) {
                    const coordinate = along(lineString, i, { units: 'meters' });
                    alongCoordinates.push(coordinate);
                }
            }

            return { 
                ...state, 
                path: { 
                    ...action.payload, 
                    length, 
                    decoded, 
                    decoded_up,
                    startPoint: {
                        ...state.path.startPoint,
                        geometry: {
                            ...state.path.startPoint.geometry,
                            coordinates: startPoint,
                        },
                    },
                    endPoint: {
                        ...state.path.endPoint,
                        geometry: {
                            ...state.path.endPoint.geometry,
                            coordinates: endPoint,
                        },
                    },
                    measurePoints: alongCoordinates,
                }, 
                info: { 
                    ...state.info, 
                    length: lengthKm 
                } 
            }
        case EVENT_FETCH_SUCCESS:
            const dateFormatted = moment(action.payload.start * 1000).format("MMMM Do YYYY HH:mm")
            return { ...state, event: action.payload, info: { ...state.info, dateFormatted } }
        case RACE_STATE_FETCH_SUCCESS:
            let timeStarted = 0;
            let timeEnded = 0;

            if (action.payload !== null) {
                timeStarted = action.payload.liveStarted;
                timeEnded = action.payload.liveEnded;
            }
            return {
                ...state,
                racestate: {
                    ...state.racestate,
                    liveStarted: timeStarted,
                    liveEnded: timeEnded,
                },
            };
        case UPDATE_DURATION: 
            return {
                ...state,
                racetime: {
                    ...state.racetime,
                    duration: action.payload.duration,
                },
            };
        case UPDATE_ETA:
            return {
                ...state,
                racetime: {
                    ...state.racetime,
                    eta: action.payload.eta,
                },
            };
        case TOGGLE_UNITS: 
            return {
                ...state,
                units: action.payload,
            };
        case FETCH_PUBLIC_PEP_POINTS:

            if (state.path.decoded_up.length > 0) {
                //Find the coordinates for the 
                const newLineString = turf.lineString(state.path.decoded_up);
                const locationOnMap = along(newLineString, action.payload.distanceLocation, { units: 'meters' });
                const newPublicPepPoint = {
                    ...action.payload,
                    locationOnMap,
                };

                return {
                    ...state,
                    publicPepPoints: [
                        ...state.publicPepPoints,
                        newPublicPepPoint,
                    ],
                };
            }
        default:
            return state;
    }
}
