import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useTranslation } from 'react-i18next'

const OnBoarding = (props: any) => {
    const { t, i18n } = useTranslation()
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const publicId = useSelector((state: RootState) => state.appState.publicId);

    useEffect(() => {
        const displayName = localStorage.getItem('displayName');
        if (displayName !== null) {
            setName(displayName);
        }
    }, []);

    const handleNameInput = (event: any) => {
        setName(event.target.value);
    };

    const handleNameSubmit = () => {
        if (name !== '') {
            localStorage.setItem('displayName', name);
            localStorage.setItem(publicId, 'true');
            props.hide();
        } else {
            setError(t('ONBOARDINGInputError'));
            setTimeout(() => {
                setError('');
            }, 3000);
        };
    };

    return (
        <div className="onboarding-overlay">
            <div className="onboarding-form">
                <label className="onboarding-welcome-label">Welcome!</label>
                <div className="onboarding-text-container">
                    <label>{t('ONBOARDINGWelcomeLine1')}</label>
                    <label>{t('ONBOARDINGWelcomeLine2')}</label>
                    <label>{t('ONBOARDINGWelcomeLine3')}</label>
                </div>
                <input type="text" className="onboarding-text-input" placeholder={t('ONBOARDINGYourName')} value={ name } onChange={ value => handleNameInput(value) } />
                {error !== '' ? <label> { error } </label> : null}
                <div className={name !== '' ? "onboarding-submit-input-filled" : "onboarding-submit-input-empty"} onClick={name !== '' ? () => handleNameSubmit() : () => {}} >
                    <label>OK</label>
                </div>
            </div>
        </div>
    );
};

export default OnBoarding;
