import * as firebase from "firebase/app";
import { FirebaseRefs } from './enums/firebaseRefs'

export var fireApp = firebase.initializeApp({
    apiKey: "AIzaSyCVrsCoQhCyVCeKasmSQ2UlOqPmH2eByF0",
    authDomain: "racepeps.firebaseapp.com",
    databaseURL: "https://racepeps.firebaseio.com",
    projectId: "racepeps",
    storageBucket: "voicemessages",
    messagingSenderId: "753012729250"
})

export const db = fireApp.database()
export const racesRef = db.ref('racepeps')

export function getFirebaseRef(ref: FirebaseRefs, publicId: String) {
  const dataRef = racesRef.child(`${publicId}/data`)
   switch (ref) {
        case FirebaseRefs.Event:
            return dataRef.child('event')
        case FirebaseRefs.Path:
            return dataRef.child('path')
        case FirebaseRefs.Responses:
            return dataRef.child('responses')
        case FirebaseRefs.LiveLocation:
            return dataRef.child('location')
        case FirebaseRefs.Track:
            return dataRef.child('track')
        case FirebaseRefs.EnqueueTrackPreRace:
            return dataRef.child('enqueueTrack/prerace/')
        case FirebaseRefs.EnqueueTrackState:
            return dataRef.child('enqueueTrack/state/')
        case FirebaseRefs.EnqueueTrackLive:
            return dataRef.child('enqueueTrack/uri/')
        case FirebaseRefs.RaceState:
            return dataRef.child('racestate')
        case FirebaseRefs.PublicPepPoints:
            return dataRef.child('publicRacePepPoints')
    }
}