
import { AppState, LOGIN_SUCCESS, DATA_FETCH_SUCCESS, AppActionTypes, INPUT_SELECTED, FIRST_MESSAGE_SENT, LOCATION_SLIDER_CHANGED, SET_UNSUPPORTED_BROWSER_WARNING } from './types'

const initialState: AppState = {
    login:false,
    dataFetched: false,
    unsupportedBrowserWarning: true,
    publicId: '',
    inputSelected: false,
    locationSliderChanged: false,
    firstMessageSent: false,
}

export function appReducer(
    state = initialState,
    action: AppActionTypes
): AppState {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: true
            };
        case DATA_FETCH_SUCCESS:
            return {
                ...state,
                dataFetched: true,
                publicId: action.payload
            };
        case INPUT_SELECTED:
            return {
                ...state,
                inputSelected: action.payload,
            };
        case LOCATION_SLIDER_CHANGED:
            return {
                ...state,
                locationSliderChanged: action.payload,
            };
        case FIRST_MESSAGE_SENT:
            return {
                ...state,
                firstMessageSent: action.payload,
            };
        case SET_UNSUPPORTED_BROWSER_WARNING:
            return {
                ...state,
                unsupportedBrowserWarning: action.payload
            }
        default:
            return state;
    }
}