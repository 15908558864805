export const FETCH_RESPONSES = 'fetch_responses';
export const FAILED_FETCH_RESPONSES = 'failed_fetch_responses';

export interface ResponseState {
    responses: Response[]
}

interface Response {
    response: string,
    timestamp: string,
    position: {
        latitude: number,
        longitude: number,
    },
    type: string,
}

interface ResponseFetchSuccessAction {
    type: typeof FETCH_RESPONSES
    payload: Response
};

interface ResponseFetchFailedAction {
    type: typeof FAILED_FETCH_RESPONSES
    payload: any
};

export type ResponseActionTypes = ResponseFetchSuccessAction | ResponseFetchFailedAction;
