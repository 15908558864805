import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../src/store/index";
import arrow from '../../svg/dm_arrow_down.svg';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import RunnerStatus from '../../RunnerStatus';
import ResponseItem from '../../ResponseItem';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next'

const welcomeTextVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const initialAnimationState = {
    opacity: 0,
    y: 30
};

const animationConfigs = {
    opacity: 1,
    y: 0,
};

const Home = () => {

    const { t, i18n } = useTranslation()
    const selectMessages = (state: RootState) => state.message.messages
    const messages = useSelector(selectMessages)
    const raceState = useSelector((state: RootState) => state.race.racestate);
    const appState = useSelector((state: RootState) => state.appState);
    const spotify = useSelector((state: RootState) => state.spotify);
    const { width } = useWindowDimensions();
    const desktop = (width > 1200);
    const responses = useSelector((state: RootState) => state.responses);

    return (
        <div className="flex-new">
            <div className="Home-bg">
                {messages.map((message, id) => {
                    return <GetCard card={message} desktop={desktop} key={ id } />
                })}
                {messages.length > 0 ?
                null
                :
                raceState.liveStarted  !== 0 && raceState.liveStarted !== undefined ?
                null
                :
                <div className="welcome-text-container">
                    {appState.inputSelected ?
                    null
                    :
                    <motion.div
                    animate={ appState.inputSelected ? 'hidden' : 'visible' }
                    transition={{ delay: 0.5, ease: 'easeOut' }}
                    variants={ welcomeTextVariants }
                    className="center-text-container">
                        <label>{t('HOMEWelcomeLine1')}</label>
                        <label>{t('HOMEWelcomeLine2')}</label>
                        <label>{t('HOMEWelcomeLine3')}</label>
                        <label>{t('HOMEWelcomeLine4')}</label>
                    </motion.div>}
                    {appState.inputSelected && !appState.locationSliderChanged ?
                    <>
                        <motion.div
                        initial={ initialAnimationState }
                        animate={ animationConfigs }
                        transition={{ delay: 0.5 }}
                        className="bottom-info-arrow-container">
                            <img src={ arrow } alt="Arrow to distance" />
                        </motion.div>
                        <motion.div
                        initial={ initialAnimationState }
                        animate={ animationConfigs }
                        transition={{ delay: 0.5 }}
                        className="bottom-info-text-container">
                            <label>{t('HomeInstructionsMessagePlays')}</label>
                        </motion.div>
                    </>
                    :
                    null}
                </div>}
                {!desktop && raceState.liveStarted !== 0 && raceState.liveStarted !== undefined ?
                <div className="live-content mobile">
                    <RunnerStatus />
                    <div className="response-container">
                        {responses.responses.length > 0 ?
                        <ul>
                            {responses.responses.map((response: any, id: number) => {
                                return (
                                    <ResponseItem response={ response } key={ id } />
                                )
                            })}
                        </ul>
                        :
                        <div className="no-responses">
                            <label>{t('HomeNoRunnerResponses')}</label>
                        </div>}
                    </div>
                    {//spotify track
                        spotify.track ? <iframe src={`https://open.spotify.com/embed/track/${spotify.track.replace("spotify:track:", "")}`} height={desktop ? "80" : "90"} allow="encrypted-media" /> : null
                    }
                </div>
                :
                null}
            </div>
        </div>

    )
}

const GetCard = (card:any, desktop: any) => {
    return (
        <motion.div
        initial={ initialAnimationState }
        animate={ animationConfigs }
        className={card.card.spotify ? "Spotify-card" :"Message-card"}>
                {
                    card.card.spotify && card.card.distance !== '0' ?
                    <div className="dm-spotify-history-container">
                    <div className="dm-spotify-history">
                        <label>{(card.card.distance / 1000).toFixed(0)}</label>
                    </div>
                    </div>
                    :
                    null
                }
                {
                    card.card.spotify ?
                    <iframe src={`https://open.spotify.com/embed/track/${card.card.spotify}`} width="100%" height={desktop.desktop ? "80" : "90"} allow="encrypted-media" />
                    :
                    <div className="dm history">
                        <label>{(card.card.distance / 1000).toFixed(0)}</label>
                    </div>   
                }
                {
                    card.card.spotify ?
                    null
                    :
                    <label className="message">{card.card.message}</label>
                }
                
        </motion.div>
    )
}

export default Home;
