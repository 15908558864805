import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import TopBar from './TopBar';
import More from './scenes/More/More';
import TopNavi from './TopNavi';
import BottomBar from './BottomBar';
import BaseScene from './BaseScene'
import Loading from './scenes/Loading/Loading'
import './scss/styles.scss';
import useWindowDimensions from "./helpers/useWindowDimensions"
import { useDispatch, useSelector } from "react-redux";
import { fetchRaceData, addRaceStateLister, removeRaceStateListener, addPublicPepPointListener, removePublicPepPointListener } from "./store/race/actions"
import { RootState } from "./store/index"
import { addResponseListener, removeResponseListener } from "./store/responses/actions";
import useUpdateLiveLocation from './hooks/HandleLiveLocationListenerHooks';
import useUpdateRaceTime from './hooks/UpdateRaceTimeHooks';
import { addSpotifyTrackListener, removeSpotifyTrackListener, addSpotifySettingsListener, removeSpotifySettingsListener, addSpotifyLiveCheckListener, removeSpotifyLiveCheckListener } from './store/spotify/actions';
import OnBoarding from "./OnBoarding";
import UnsupportedBrowser from "./UnsupportedBrowser"
declare global {
    interface Window {
        grecaptcha: any
    }
}

interface Token {
    access_token: any,
    expires_in: any,
}

const App = () => {

    const dispatch = useDispatch()
    const { addLiveLocationListener, removeLiveLocationListener } = useUpdateLiveLocation();
    const { setTimer, clearTimer } = useUpdateRaceTime();
    const { width, height } = useWindowDimensions();
    const desktop = (width > 1200)

    const selectIsFetched = (state: RootState) => state.appState.dataFetched
    const fetched = useSelector(selectIsFetched)
    const selectPublicId = (state: RootState) => state.appState.publicId
    const selectUnsupportedBrowserWarning = (state: RootState) => state.appState.unsupportedBrowserWarning
    const unsupportedBrowserWarning = useSelector(selectUnsupportedBrowserWarning)
    const publicId = useSelector(selectPublicId)
    const racestate = useSelector((state: RootState) => state.race.racestate);
    const notRace = useSelector((state: RootState) => state.race.event.notRace);
    const [onBoardingVisible, setOnBoardingVisible] = useState(false);
    const [accessToken, setAccessToken] = useState<Token>({
        access_token: null,
        expires_in: null,
    });

    const initialAuthSpotify = async () => {
        let authResponse: any = {
            access_token: null
        };
        authResponse = await fetch(`https://us-central1-racepeps.cloudfunctions.net/gettoken`)
        authResponse = await authResponse.json();
        if (authResponse.access_token){
            setAccessToken({
                access_token: authResponse.access_token,
                expires_in: (new Date().getTime() / 1000) + authResponse.expires_in - 30,
            })
        }
    }

    useEffect(() => {
        const pathParams = window.location.pathname.split('/')
        const publicId = pathParams[1]
        if (!publicId) {
            window.location.replace('https://pepsapp.com/')
        } else {
            dispatch(fetchRaceData(publicId))
        }
    }, [])



/*     useEffect(() => {
        if (notRace === true) {
            window.location.href = 'https://pepsapp.com/notfound';
        }
    }, [notRace]); */

    useEffect(() => {
        if (publicId) {
            if (localStorage.getItem(publicId) === null) {
                setOnBoardingVisible(true);
            }
            dispatch(addRaceStateLister(publicId));
            dispatch(addPublicPepPointListener(publicId));
            dispatch(addResponseListener(publicId));

            return () => {
                removeRaceStateListener();
                removeResponseListener();
                removePublicPepPointListener();
            }
        }
    }, [publicId]);

    useEffect(() => {
        if (racestate.liveStarted !== undefined && racestate.liveStarted !== 0 && publicId) {
            addLiveLocationListener(publicId);

            return () => {
                removeLiveLocationListener();
            }
        }
    }, [racestate, publicId]);

    useEffect(() => {
        if (racestate.liveEnded !== 0 && racestate.liveEnded !== undefined) {
            clearTimer();
        } else if (racestate.liveStarted !== 0 && racestate.liveStarted !== undefined) {
            setTimer();
        }
    }, [racestate]);

    //Add spotify listeners
    useEffect(() => {
        const splitAddress = window.location.pathname.split('/');
        const pathPublicId = splitAddress[1];
        dispatch(addSpotifyTrackListener(pathPublicId));
        dispatch(addSpotifySettingsListener(pathPublicId));
        dispatch(addSpotifyLiveCheckListener(pathPublicId));

        return () => {
            removeSpotifyTrackListener();
            removeSpotifySettingsListener();
            removeSpotifyLiveCheckListener();
        }
    }, []);

    useEffect(() => {
        if ((accessToken.expires_in && (new Date().getTime() / 1000) > accessToken.expires_in) || !accessToken.expires_in){
            initialAuthSpotify();
        }
    },[]);

    return <>
        {!fetched ? (
            <div className="main-div">
                <Loading />
            </div>
        ) : unsupportedBrowserWarning ? (
                <UnsupportedBrowser />
            ) : (
                    <div className="main-div">
                        {onBoardingVisible ? <OnBoarding hide={() => setOnBoardingVisible(false)} /> : null}
                        {!desktop &&
                            <div>
                                <TopBar />
                                <TopNavi />
                            </div>
                        }
                        <Route path="/main" render={() => <BaseScene page={'Home'} accessToken={accessToken} setAccessToken={setAccessToken} />} />
                        <Route path="/map" render={() => <BaseScene page={'Map'} accessToken={accessToken} setAccessToken={setAccessToken} />} />
                        <Route path="/more" component={More} />

                        {!desktop &&
                            <BottomBar accessToken={accessToken} setAccessToken={setAccessToken} />
                        }

                    </div>
            )}
        </>
}

export default withRouter(App)