import { useDispatch } from 'react-redux';
import length from '@turf/length';
import along from '@turf/along';
import bearing from '@turf/bearing';
import * as turf from '@turf/helpers';
import { updateLiveLocation } from '../store/livelocation/actions';

interface Coordinates {
    coordinates: {
        latitude: Number,
        longitude: Number,
    },
    time: String,
};

const animationCoordinates = {
    startLocation: {} as Coordinates,
    endLocation: {} as Coordinates,
    nextLocation: {} as Coordinates,
};

const animationRoute = {
    type: 'Feature' as 'Feature',
    geometry: {
        type: 'LineString' as 'LineString',
        coordinates: [] as any,
    },
    properties: {},
};

let counter = 0;
let arc = [] as any;
let animating = false;
const steps = 200;

const useAnimateLiveLocation = () => {
    const dispatch = useDispatch();

    const setCoordinates = (coordinates: Coordinates) => {
        if (!animationCoordinates.startLocation.hasOwnProperty('coordinates')) {
            animationCoordinates.startLocation = coordinates;
        } else if (!animationCoordinates.endLocation.hasOwnProperty('coordinates')) {
            animationCoordinates.endLocation = coordinates;
        } else {
            animationCoordinates.nextLocation = coordinates;
        }

        triggerAnimationQueue();
    };

    const triggerAnimationQueue = () => {
        if (animationCoordinates.startLocation.hasOwnProperty('coordinates') && animationCoordinates.endLocation.hasOwnProperty('coordinates')) {
            if (animating === false) {
                animating = true;

                const start = [animationCoordinates.startLocation.coordinates.longitude, animationCoordinates.startLocation.coordinates.latitude];
                const end = [animationCoordinates.endLocation.coordinates.longitude, animationCoordinates.endLocation.coordinates.latitude];
    
                animationRoute.geometry.coordinates = [start, end];
    
                const lineLength = length(animationRoute);
    
                for (let i = 0; i < lineLength; i += lineLength / steps) {
                    const segment = along(animationRoute, i);
                    arc.push(segment.geometry.coordinates);
                };
    
                animationRoute.geometry.coordinates = arc;
    
                animate();
            }
        }
    };

    const animate = () => {
        try {
            const locationArrowCoordinates = animationRoute.geometry.coordinates[counter];

            const firstPoint = turf.point(animationRoute.geometry.coordinates[counter >= steps ? counter - 1 : counter]);
            const secondPoint = turf.point(animationRoute.geometry.coordinates[counter >= steps ? counter : counter + 1]);
            const locationArrowBearing = bearing(firstPoint, secondPoint);

            dispatch(updateLiveLocation(locationArrowCoordinates, locationArrowBearing));

            if (counter < steps) {
                requestAnimationFrame(animate)
            } else {
                resetAnimation();
            }

            counter = counter + 1;
        } catch (error) {
            resetAnimation();
        }
    };

    const resetAnimation = () => {
        animationCoordinates.startLocation = animationCoordinates.endLocation;
        arc = [];
        counter = 0;
        animating = false;

        if (animationCoordinates.nextLocation) {
            if (animationCoordinates.endLocation.coordinates !== animationCoordinates.nextLocation.coordinates) {
                animationCoordinates.endLocation = animationCoordinates.nextLocation;
                triggerAnimationQueue();
            } else {
                animationCoordinates.nextLocation = {} as Coordinates;
            }
        } else {
            console.log('No animationCoordinates.nextLocation');
        }
    };

    return {
        setCoordinates,
    }
};

export default useAnimateLiveLocation;
