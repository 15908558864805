import { MessageState, MessageActionTypes, REQUEST_START, REQUEST_SUCCESS, RESET_SENDER, ADD_NEW_MESSAGE, REQUEST_ERROR } from "./types";

const initialState: MessageState = {
    sender: {
        sending: false,
        sent: false,
        error: false,
        errorText: '',
    },
    messages: []
};

export function messageReducer(
    state = initialState,
    action: MessageActionTypes
): MessageState {
    switch (action.type) {
        case REQUEST_START:
        return {...state, sender: {...state.sender, sending: true}}
        case REQUEST_SUCCESS:
            return { ...state, sender: { ...state.sender, sent:true, sending: false } }
        case REQUEST_ERROR:
            return { ...state, sender: { ...state.sender, error: true, errorText: action.payload, sending: false }}
        case RESET_SENDER:
            return { ...state, sender: { ...initialState.sender }}
        case ADD_NEW_MESSAGE:
            return { ...state, messages: [...state.messages, action.payload]}
        default:
            return state;
    }
}//todo combinereducers
