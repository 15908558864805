import React from 'react';
import { Link, useLocation } from "react-router-dom";
import HomeActive from './svg/home_active.svg';
import HomeInactive from './svg/home_inactive.svg';
import MapActive from './svg/map_active.svg';
import MapInactive from './svg/map_inactive.svg';
import MoreActive from './svg/more_active.svg';
import MoreInactive from './svg/more_inactive.svg';
import { useTranslation } from 'react-i18next'

const TopNavi = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation()
    const currentPath = location.pathname
    const paths = [
        {path: '/main', name: t('TOPNAVIHome')},
        {path: '/map', name: t('TOPNAVIMap')},
        {path: '/more', name: t('TOPNAVIMore')}]

    return (
        <div className="Navi">
            {paths.map(item => {
                let naviClass = (item.path === currentPath) ? 'Navi-link-container active' : 'Navi-link-container'
                let imageSource;

                if (item.name === t('TOPNAVIHome')) {
                    if (item.path === currentPath) {
                        imageSource = HomeActive;
                    } else {
                        imageSource = HomeInactive;
                    }
                } else if (item.name === t('TOPNAVIMap')) {
                    if (item.path === currentPath) {
                        imageSource = MapActive;
                    } else {
                        imageSource = MapInactive;
                    }
                } else if (item.name === t('TOPNAVIMore')) {
                    if (item.path === currentPath) {
                        imageSource = MoreActive;
                    } else {
                        imageSource = MoreInactive;
                    }
                }

                return(
                    <div className="Navi-item" key={item.path}>
                        <div className={naviClass}>
                            <img src={imageSource} alt="Navigation image" />
                            <Link className="Navi-link" to={item.path}>{item.name}</Link>
                        </div>
                    </div>

                )
            })

            }
        </div>

    )
}

export default TopNavi;
