import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import BottomBar from './BottomBar';
import { useSelector } from "react-redux"
import { RootState } from "../src/store/index"

import Map from './scenes/Map/Map';
import Home from './scenes/Home/Home';
import More from './scenes/More/More';
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'

import useWindowDimensions from "./helpers/useWindowDimensions"
import SpotifyModal from './SpotifyModal';

/*interface BaseSceneProps {
    page: string
}*/

const BaseScene = (props: /*BaseSceneProps*/any) => {
    const { t, i18n } = useTranslation()
    const { width } = useWindowDimensions();
    const desktop = (width > 1200)
    const history = useHistory()
    const location = useLocation();
    const selectRace = (state: RootState) => state.race
    const race = useSelector(selectRace)

    function switchPage() {
        console.log(location)
        if (location.pathname === '/map') {
            history.push('/main')
        }
        if (location.pathname === '/main') {
            history.push('/map')
        }
    }

    const isMapSelected = (location.pathname === '/map')
    const buttonText = isMapSelected ? t('BASEHideMap').replace('\\n', '\n') : t('BASEShowMap').replace('\\n', '\n')

    let mainElement

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const selectSpotify = (state: RootState) => state.spotify

    const spotify = useSelector(selectSpotify)

    switch (props.page) {
        case 'Home':
            mainElement = <Home />
            break
        case 'Map':
            mainElement = <Map />
            break
    }

    if (desktop) {
        return <div className="row">
            {race.path.decoded_up.length === 0 ?
            <>
                {race.racestate.liveStarted !== 0 && race.racestate.liveStarted !== undefined && race.event.tracking === true ?
                <motion.div className={isMapSelected ? 'switch-scene-button color-bg' : 'switch-scene-button image-bg' } onClick={() => switchPage()} whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
                    <label>{buttonText}</label>
                </motion.div>
                :
                null}
            </>
            :
            <motion.div className={isMapSelected ? 'switch-scene-button color-bg' : 'switch-scene-button image-bg' } onClick={() => switchPage()} whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
                <label>{buttonText}</label>
            </motion.div>}
            <More inBaseScene={true} />
            <div className="main-div">

                <h1 className="race-title">
                    {race.event.name}
                    {
                        spotify.enqueueTrack.state.connected && spotify.enqueueTrack.state.enqueueTrack ? <img onClick={handleShow} src={require('./img/spotify_green.png')} alt={'spotify_logo'} /> : null
                    }
                </h1>
                {
                    show && spotify.enqueueTrack.state.connected && spotify.enqueueTrack.state.enqueueTrack ? <SpotifyModal handleClose={handleClose} /> : null
                }
                {mainElement}
                <BottomBar accessToken={props.accessToken} setAccessToken={props.setAccessToken}/>
            </div>

            </div>
    } else {
        return <>{mainElement}</>
    }



}

export default BaseScene;
