import * as turf from '@turf/helpers'


export interface FormState {
    locationMeters: string,
    userSelectedLocation: boolean,
    position?: turf.Position,
}

export interface UpdateLocationPayload {
    locationMeters: string,
    position?: turf.Position
}

export const UPDATE_LOCATION_METERS = 'update_location_meters'
export const RESET_FORM = 'reset_form'

interface UpdateLocationAction {
    type: typeof UPDATE_LOCATION_METERS;
    payload: UpdateLocationPayload;
}

interface ResetFormAction {
    type: typeof RESET_FORM
}

export type FormActionTypes = UpdateLocationAction | ResetFormAction;