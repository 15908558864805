import React, { useState } from 'react';

import { useSelector } from "react-redux";

import { RootState } from "../src/store/index"

import SpotifyModal from "./SpotifyModal";

const TopBar = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const selectSpotify = (state: RootState) => state.spotify
    const selectRace = (state: RootState) => state.race
    const race = useSelector(selectRace)
    const spotify = useSelector(selectSpotify)
    
    return (    
        <div className="TopBar">
            <h1 className="TopBar-Title">{race.event?.name || ''}</h1>
            {
                spotify.enqueueTrack.state.connected && spotify.enqueueTrack.state.enqueueTrack ? <img onClick={handleShow} src={require('./img/spotify_green.png')} alt={'spotify_logo'} className="spotify-button" /> : null
            }
            {
                show && spotify.enqueueTrack.state.connected && spotify.enqueueTrack.state.enqueueTrack ? <SpotifyModal handleClose={handleClose} /> : null
            }
        </div>

    )
}

export default TopBar;
