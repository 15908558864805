import React, { useState } from 'react';
import unitsActive from './svg/units_active.svg';
import unitsInactive from './svg/units_inactive.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store';
import { toggleUnits } from './store/race/actions';
import { useTranslation } from 'react-i18next'

const UnitsContainer = () => {
    const units = useSelector((state: RootState) => state.race.units);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()

    const switchUnits = (unitsName: string) => {
        dispatch(toggleUnits(unitsName));
    }

    const kmStyle = (units === 'kilometers') ? 'unit active' : 'unit'
    const milesStyle = (units === 'miles') ? 'unit active' : 'unit'

    return (
        <div className="units-container">


            <div className="units-switches">
                <label>
                    <span onClick={() => switchUnits('kilometers')} className={kmStyle}>km</span>
                    <span className="unit">/</span>
                    <span onClick={() => switchUnits('miles')} className={milesStyle}>mi</span>
                </label>

        </div>
        </div>
    );
};

export default UnitsContainer;
