import { UPDATE_LOCATION_METERS, FormState, FormActionTypes, RESET_FORM } from './types'


const initialState: FormState = {
    locationMeters: "0",
    userSelectedLocation: false,
}

export function formReducer(
    state = initialState,
    action: FormActionTypes
): FormState {
    switch (action.type) {
        case UPDATE_LOCATION_METERS:
            return { ...state, locationMeters: action.payload.locationMeters, position: action.payload.position, userSelectedLocation: true }
        case RESET_FORM: {
            return { ...initialState }
        }
        default:
            return state;
    }
}