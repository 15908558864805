import { LiveLocationState, LiveLocationTypes, UPDATE_LOCATION_ARROW, UPDATE_LIVE_TAILING, FETCH_ROUTE_HISTORY, FETCH_LENGTH } from "./types";

const initialState: LiveLocationState = {
    locationArrow: {
        type: 'Feature',
        properties: {
            bearing: 0,
        },
        geometry: {
            type: 'Point',
            coordinates: [],
        },
    },
    liveTail: {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'LineString',
            coordinates: [],
        },
    },
    length: 0.00,
};

export const liveLocationReducer = (state = initialState, action: LiveLocationTypes): LiveLocationState => {
    switch (action.type) {
        case UPDATE_LOCATION_ARROW:
            return {
                ...state,
                locationArrow: {
                    ...state.locationArrow,
                    geometry: {
                        ...state.locationArrow.geometry,
                        coordinates: action.payload.coordinates,
                    },
                    properties: {
                        bearing: action.payload.bearing
                    }
                },
            };
        case UPDATE_LIVE_TAILING:
            return {
                ...state,
                liveTail: {
                    ...state.liveTail,
                    geometry: {
                        ...state.liveTail.geometry,
                        coordinates: [...state.liveTail.geometry.coordinates, action.payload.coordinates],
                    },
                },
            };
        case FETCH_ROUTE_HISTORY:
            return {
                ...state,
                liveTail: {
                    ...state.liveTail,
                    geometry: {
                        ...state.liveTail.geometry,
                        coordinates: action.payload.coordinates
                    }
                }
            };
        case FETCH_LENGTH:
            return {
                ...state,
                length: action.payload,
            }
        default:
            return state;
    };
};
