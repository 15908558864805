import { FETCH_SPOTIFY_TRACK, FAILED_FETCH_SPOTIFY_TRACK, FETCH_SPOTIFY_ENQUEUETRACK_STATE, FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE, FETCH_SPOTIFY_ENQUEUETRACK_LIVE, FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE } from './types';
import { FirebaseRefs } from '../../enums/firebaseRefs';
import { getFirebaseRef } from '../../firebase';

let trackListener: any = null;
let spotifySettingsListener: any = null;
let spotifyLiveCheckListener: any = null;

export const addSpotifyTrackListener = (publicId: string) => {
    return (dispatch: any) => {
        try {
            trackListener = getFirebaseRef(FirebaseRefs.Track, publicId);
            return trackListener.on('value', (snapshot: any) => {
                if (snapshot.val()){
                    dispatch({ type: FETCH_SPOTIFY_TRACK, payload: snapshot.val().uri });
                } else {
                    dispatch({ type: FAILED_FETCH_SPOTIFY_TRACK, payload: null });
                }
            });
        } catch (error) {
            dispatch({ type: FAILED_FETCH_SPOTIFY_TRACK, payload: null });
        }
    };
};

export const removeSpotifyTrackListener = () => {
    trackListener.off('value');
};

export const addSpotifySettingsListener = (publicId: string) => {
    return (dispatch: any) => {
        try {
            spotifySettingsListener = getFirebaseRef(FirebaseRefs.EnqueueTrackState, publicId);
            return spotifySettingsListener.once('value', (snapshot: any) => {
                if (snapshot.val()){
                    dispatch({ type: FETCH_SPOTIFY_ENQUEUETRACK_STATE, payload: snapshot.val() });
                } else {
                    dispatch({ type: FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE, payload: { connected: false, enqueueTrack: false }});
                }
                return spotifySettingsListener.on('value', (snapshot: any) => {
                    if (snapshot.val()){
                        dispatch({ type: FETCH_SPOTIFY_ENQUEUETRACK_STATE, payload: snapshot.val() });
                    } else {
                        dispatch({ type: FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE, payload: { connected: false, enqueueTrack: false }});
                    }
                });
            });
        } catch (error) {
            dispatch({ type: FAILED_FETCH_SPOTIFY_ENQUEUETRACK_STATE, payload: { connected: false, enqueueTrack: false }});
        }
    };
};

export const removeSpotifySettingsListener = () => {
    spotifySettingsListener.off('value');
};

export const addSpotifyLiveCheckListener = (publicId: string) => {
    return (dispatch: any) => {
        try {
            spotifyLiveCheckListener = getFirebaseRef(FirebaseRefs.EnqueueTrackLive, publicId);
            return spotifyLiveCheckListener.once('value', (snapshot: any) => {
                if (snapshot.val()){
                    dispatch({ type: FETCH_SPOTIFY_ENQUEUETRACK_LIVE, payload: snapshot.val() });
                } else {
                    dispatch({ type: FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE, payload: { uri: null }});
                }
                return spotifyLiveCheckListener.on('value', (snapshot: any) => {
                    if (snapshot.val()){
                        dispatch({ type: FETCH_SPOTIFY_ENQUEUETRACK_LIVE, payload: snapshot.val() });
                    } else {
                        dispatch({ type: FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE, payload: { uri: null }});
                    }
                });
            });
        } catch (error) {
            dispatch({ type: FAILED_FETCH_SPOTIFY_ENQUEUETRACK_LIVE, payload: { uri: null }});
        }
    };
};

export const removeSpotifyLiveCheckListener = () => {
    spotifyLiveCheckListener.off('value');
};

export const enqueueUri = (publicId: string, prerace: boolean, uri: string, distance: string = '0') => {
    return () => {
        try {
            if (prerace){
                return getFirebaseRef(FirebaseRefs.EnqueueTrackPreRace, publicId).push({
                    uri: uri,
                    sender: localStorage.getItem('displayName'),
                    distance: distance
                });
            } else {
                return getFirebaseRef(FirebaseRefs.EnqueueTrackLive, publicId).update({
                    uri: uri,
                    sender: localStorage.getItem('displayName')
                });
            }
        } catch (error) {
            console.log('error in enqueue uri')
        }
    };
};

